import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const removeUUIDsFromString = (inputString: string) => {
  // Match UUID pattern (e.g., 123e4567-e89b-12d3-a456-426655440000)
  const uuidPattern = /\b[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}\b/g;

  // Match nanoid pattern (e.g., g5z9Jr5gKqN8LrmG)
  const nanoidPattern = /\b^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+\b/g;

  // Replace UUID and nanoid patterns with an empty string
  const outputString = inputString.replace(uuidPattern, '').replace(nanoidPattern, '').replace(/\s+/g, ' ');

  return outputString;
};

const pathToTitle = (path: string) => {
  path = path.replace(/^\/|\/$/g, '');
  path = path.replace(/#/g, ' ');
  const segments = path.split('/');
  const convertedString = segments.map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1)).join(' ');
  const cleanString = removeUUIDsFromString(convertedString);

  return cleanString;
};

const GAListener = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search + location.hash;
    const title = pathToTitle(page);
    ReactGA.send({ hitType: 'pageview', page: page, title: title });
  }, [location]);

  return <>{children}</>;
};

export { GAListener, pathToTitle };
