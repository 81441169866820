import { ID, uuid } from '../../_metronic/helpers/crud-helper/models';
import { useQuery, useMutation, useQueryClient, QueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

import {
  Service,
  ProviderService,
  ProviderTimeSlot,
  ProviderTimeSlotService,
  Provider,
  Client,
  Guardian,
  TimeSlot,
  Event,
  ClientProvider,
  ClientOnboarding,
  AgeGroup,
  Language,
  Expertise,
  Concern,
  IWizForm,
  IWizFormTemplate,
  IWizFormTemplateExists,
  Note,
  Document,
  Clinic,
  PostalCode,
  ClientInvite,
  EventLog,
  IInvoice,
  IIcsIntegration,
  ClientSubscription,
} from './models';
import { AvailabilityList, AvailableTimeSlot } from '../modules/apps/booking/core/_models';
import { useAuth } from '../modules/auth';
import queryString from 'query-string';
import qs from 'qs';
import { monitoringCaptureError } from './monitoring';

const get_api_url = () => {
  if (process.env.REACT_APP_API_URL) return process.env.REACT_APP_API_URL;
  if (location.hostname === 'localhost') return 'http://localhost:8000/api/v1';
  if (location.hostname.includes('sta')) return 'https://api-staging.wiztherapy.com/api/v1';
  return 'https://api.wiztherapy.com/api/v1';
};

export const API_URL = get_api_url();

//export const GET_SERVICES_FOR_SLOT_URL = (slotId: uuid) => `${API_URL}/stafftimeslot/${slotId}/services/`;
export const GET_PROVIDER_SERVICES_URL = (timeslot: boolean) =>
  `${API_URL}/provider-services/?timeslot__isnull=${!timeslot}`;
export const GET_PROVIDER_SERVICE_URL = (uuid: uuid) => `${API_URL}/provider-services/${uuid}/`;
export const PROVIDER_SERVICE_CREATE_URL = `${API_URL}/provider-services/`;
export const CLIENT_URL = `${API_URL}/clients/`;
export const GUARDIAN_URL = `${API_URL}/guardians/`;
export const CLIENT_PROVIDER_URL = `${API_URL}/client-providers/`;
export const CLIENT_SUBSCRIPTIONS_URL = `${API_URL}/client-subscriptions/`;
export const PROVIDER_URL = `${API_URL}/providers/`;
export const CLINIC_PROVIDER_URL = `${API_URL}/providers/clinic/`;
export const PROVIDER_CONNECTIONS_URL = `${API_URL}/provider-connections/`;
//export const PROVIDER_PROVIDER_URL = `${API_URL}/provider-providers/`;
export const SERVICE_TIMESLOT_URL = (service_uuid: uuid) => `${API_URL}/services/${service_uuid}/timeslots/`;
export const FORM_TEMPLATE_URL = (name_or_uuid: uuid | string) => `${API_URL}/form-templates/${name_or_uuid}/`;
export const FORM_TEMPLATES_URL = `${API_URL}/form-templates/`;
export const FORM_URL = `${API_URL}/forms/`;
export const CLINIC_URL = `${API_URL}/clinics/`;
export const PROVIDER_REFERRALS_URL = (uuid: uuid) => `${API_URL}/providers/${uuid}/referrals/`;
export const REFERRALS_URL = `${API_URL}/referrals/`;

function useServices(onError?: (error?: any) => void) {
  return useQuery(
    'services',
    async (): Promise<Array<Service>> => {
      const { data } = await axios.get(`${API_URL}/services/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useServices',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useService(uuid?: uuid, onError?: (error?: any) => void) {
  return useQuery(
    ['service', uuid],
    async (): Promise<Service> => {
      const { data } = await axios.get(`${API_URL}/services/${uuid}/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useService',
          data: { uuid },
        });
        if (onError) onError(error);
        else throw error;
      },
      enabled: !!uuid,
    }
  );
}

function useProviderServices(props?: {
  distinct?: boolean;
  withTimeslot?: any;
  enabled?: boolean;
  provider?: Provider;
  onError?: (error?: any) => void;
}) {
  return useQuery(
    ['provider-services', props?.provider?.uuid],
    async (): Promise<Array<ProviderService>> => {
      const { data } = await axios.get(
        props?.provider
          ? `${API_URL}/providers/${props?.provider.uuid}/services/?timeslot__isnull=${!props?.withTimeslot}`
          : `${API_URL}/provider-services/?timeslot__isnull=${!props?.withTimeslot}&distinct=${!!props?.distinct}`
      );
      return data;
    },
    {
      enabled: props?.enabled !== false,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useService',
          data: { distinct: props?.distinct, withTimeslot: props?.withTimeslot, enabled: props?.enabled },
        });
        if (props?.onError) props?.onError(error);
        else throw error;
      },
    }
  );
}

function getProviderService(uuid: uuid) {
  return axios
    .get(`${API_URL}/provider-services/${uuid}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getProviderService',
        data: { uuid },
      });
      throw error;
    });
}

function getProviderServicesCount(provider?: Provider) {
  return axios
    .get(`${API_URL}/providers/${provider?.uuid}/services/count/`)
    .then((res) => {
      return res.data?.count;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getProviderServiceCount',
        data: { provider: provider?.uuid },
      });
      throw error;
    });
}

function useUpdateProviderService(
  providerService: ProviderService | undefined,
  update: ProviderService,
  onSuccess?: (provider_service: ProviderService) => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.patch(`${API_URL}/provider-services/${providerService?.uuid}/`, update);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('provider-services');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateProviderService',
          data: { providerService, update },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function setupProviderServices(provider: Provider, config: any) {
  return axios
    .post(`${API_URL}/providers/${provider?.uuid}/services/setup/`, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'setupProviderServices',
        data: { provider: provider?.uuid, config: config },
      });
      throw error;
    });
}

function useCreateProviderService(
  service: Service,
  providerService: ProviderService,
  onSuccess?: (provider_service: ProviderService) => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.post(`${API_URL}/provider-services/`, providerService);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('provider-services');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useCreateProviderService',
          data: { providerService },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useProviderTimeSlots(onError?: (error?: any) => void) {
  return useQuery(
    'provider-timeslots',
    async (): Promise<Array<ProviderTimeSlot>> => {
      const { data } = await axios.get(`${API_URL}/provider-timeslots/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useProviderTimeSlots',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useUpdateProviderTimeSlot(props: {
  providerTimeSlot: ProviderTimeSlot;
  update: ProviderTimeSlot;
  urlOptions?: any;
  onSuccess?: (providerTimeSlot: ProviderTimeSlot) => void;
  onError?: (error?: any) => void;
}) {
  const { providerTimeSlot, update, urlOptions, onSuccess, onError } = props;
  const urlString = urlOptions ? queryString.stringify(urlOptions) : '';
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.patch(`${API_URL}/provider-timeslots/${providerTimeSlot?.uuid}/?${urlString}`, update);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('provider-timeslots');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateProviderTimeSlot',
          data: { providerTimeSlot, update },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useCreateProviderTimeSlot(props: {
  providerTimeSlot: ProviderTimeSlot;
  urlOptions?: any;
  onSuccess?: (providerTimeSlot: ProviderTimeSlot) => void;
  onError?: (error?: any) => void;
}) {
  const { providerTimeSlot, urlOptions, onSuccess, onError } = props;
  const urlString = urlOptions ? queryString.stringify(urlOptions) : '';
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.post(`${API_URL}/provider-timeslots/?${urlString}`, providerTimeSlot);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('provider-timeslots');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useCreateProviderTimeSlot',
          data: { providerTimeSlot },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useProviderTimeSlotServices(onError?: (error?: any) => void) {
  return useQuery(
    'provider-timeslot-services',
    async (): Promise<Array<ProviderTimeSlotService>> => {
      const { data } = await axios.get(`${API_URL}/provider-timeslot-services/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useProviderTimeSlotServices',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function setupProviderTimeSlot(provider: Provider, config: any) {
  return axios
    .post(`${API_URL}/providers/${provider?.uuid}/timeslots/setup/`, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'setupProviderTimeSlot',
        data: { provider: provider?.uuid, config: config },
      });
      throw error;
    });
}

function getProviderTimeSlotCount(provider?: Provider) {
  return axios
    .get(`${API_URL}/providers/${provider?.uuid}/timeslots/count/`)
    .then((res) => {
      return res.data?.count;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getProviderTimeSlotCount',
        data: { provider: provider?.uuid },
      });
      throw error;
    });
}

function useProviderMe(onError?: (error?: any) => void) {
  return useQuery(
    ['providers', 'me'],
    async (): Promise<Provider> => {
      const { data } = await axios.get(`${API_URL}/providers/me/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useProviderMe',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useProvider(id?: uuid, onError?: (error?: any) => void) {
  return useQuery(
    ['providers', id],
    async (): Promise<Provider> => {
      const { data } = await axios.get(`${API_URL}/providers/${id}/`);
      return data;
    },
    {
      enabled: !!id,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useProvider',
          id: id,
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useClinic(clinic_uuid: uuid | undefined, onError?: (error?: any) => void) {
  return useQuery(
    ['clinic'],
    async (): Promise<Clinic> => {
      const { data } = await axios.get(`${API_URL}/clinics/${clinic_uuid}/`);
      return data;
    },
    {
      enabled: clinic_uuid !== undefined,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useClinic',
          data: { clinic_uuid },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useUpdateProvider(
  provider: Provider,
  update: Provider,
  onSuccess?: (provider: Provider) => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.patch(`${API_URL}/providers/${provider?.uuid}/`, update);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('providers');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateProvider',
          data: { provider, update },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useClients(props?: { enabled?: boolean; options?: any; onError?: (error?: any) => void }) {
  const { currentUser } = useAuth();
  const query = props?.options ? qs.stringify(props.options, { skipNulls: true }) : '';
  return useQuery(
    'clients',
    async (): Promise<Array<Client>> => {
      const { data } = await axios.get(`${API_URL}/clients/?${query}`);
      return data;
    },
    {
      enabled: props?.enabled !== false && currentUser !== undefined,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useClients',
          data: { enabled: props?.enabled },
        });
        if (props?.onError) props?.onError(error);
        else throw error;
      },
    }
  );
}

function getClientsCount() {
  return axios
    .get(`${API_URL}/clients/count/`)
    .then((res) => {
      return res.data?.count;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getClientsCount',
      });
      throw error;
    });
}

function useGuardians(onError?: (error?: any) => void) {
  return useQuery(
    'guardians',
    async (): Promise<Array<Guardian>> => {
      const { data } = await axios.get(`${API_URL}/guardians/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useGuardians',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useProviders(onError?: (error?: any) => void) {
  return useQuery(
    ['providers'],
    async (): Promise<Array<Provider>> => {
      const { data } = await axios.get(`${API_URL}/providers/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useProviders',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function getProviderPaymentEnabled(provider: Provider) {
  return axios
    .get(`${API_URL}/providers/${provider.uuid}/payment_enabled/`)
    .then((res) => {
      return res.data?.payment_enabled;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getProviderPaymentEnabled',
      });
      throw error;
    });
}

function useAges(onError?: (error?: any) => void) {
  return useQuery(
    'ages',
    async (): Promise<Array<AgeGroup>> => {
      const { data } = await axios.get(`${API_URL}/ages/`);
      return data;
    },
    {
      cacheTime: 24 * 60 * 60 * 1000,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useAges',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useExpertises(onError?: (error?: any) => void) {
  return useQuery(
    'expertises',
    async (): Promise<Array<Expertise>> => {
      const { data } = await axios.get(`${API_URL}/expertises/`);
      return data;
    },
    {
      cacheTime: 24 * 60 * 60 * 1000,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useExpertises',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useConcerns(onError?: (error?: any) => void) {
  return useQuery(
    'concerns',
    async (): Promise<Array<Concern>> => {
      const { data } = await axios.get(`${API_URL}/concerns/`);
      return data;
    },
    {
      cacheTime: 24 * 60 * 60 * 1000,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useConcerns',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useLanguages(onError?: (error?: any) => void) {
  return useQuery(
    'languages',
    async (): Promise<Array<Language>> => {
      const { data } = await axios.get(`${API_URL}/languages/`);
      return data;
    },
    {
      cacheTime: 24 * 60 * 60 * 1000,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useLanguages',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function usePostalCodes(query: string | undefined, from?: string, onError?: (error?: any) => void) {
  return useQuery(
    ['postal-codes', query],
    async (): Promise<Array<PostalCode>> => {
      const { data } = await axios.get(`${API_URL}/postalcodes/?search=${query}${from ? `&from=${from}` : ''}`);
      return data;
    },
    {
      cacheTime: 24 * 60 * 60 * 1000,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'usePostalCodes',
        });
        if (onError) onError(error);
        else throw error;
      },
      enabled: !!query && query.trim().length > 0,
    }
  );
}

const getPostalCode = async (
  postalCodeString: string,
  onError?: (error?: any) => void
): Promise<PostalCode | undefined> => {
  const res = await axios.get(`${API_URL}/postalcodes/?search=${postalCodeString}`);
  if (res?.data && res?.data.length > 0) return res?.data[0];
  return undefined;
};

function useTimeSlots(onError?: (error?: any) => void) {
  return useQuery(
    'timeslots',
    async (): Promise<Array<TimeSlot>> => {
      const { data } = await axios.get(`${API_URL}/timeslots/`);
      return data;
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useTimeSlots',
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useUpdateTimeSlot(
  timeSlot: TimeSlot,
  update: TimeSlot,
  onSuccess?: (timeSlot: TimeSlot) => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.patch(`${API_URL}/timeslots/${timeSlot?.uuid}/`, update);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('timeslots');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateTimeSlot',
          data: { timeSlot, update },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useCreateTimeSlot(
  timeSlot: TimeSlot,
  onSuccess?: (timeSlot: TimeSlot) => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.post(`${API_URL}/timeslots/`, timeSlot);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('timeslots');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useCreateTimeSlot',
          data: { timeSlot },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function getEvents(props: {
  start?: Date;
  end?: Date;
  client?: Client;
  singleEvents?: boolean;
  onError?: (error?: any) => void;
}) {
  const { start, end, client, singleEvents, onError } = props;
  return axios
    .get(
      `${API_URL}/events/?start_date=${start?.toISOString()}&end_date=${end?.toISOString()}${
        client ? `&client=${client.uuid}` : ''
      }${singleEvents ? `&single_events=true` : ''}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getEvents',
        data: { start, end, client: client && client.uuid, singleEvents },
      });
      throw error;
    });
}

function useEvents(props: {
  start?: Date;
  end?: Date;
  client?: Client;
  singleEvents?: boolean;
  onError?: (error?: any) => void;
}) {
  const { currentProvider } = useAuth();
  const { start, end, client, singleEvents, onError } = props;
  return useQuery(
    ['events', { start, end }, client?.uuid, currentProvider?.uuid],
    async (): Promise<Array<Event>> => {
      return await getEvents(props);
    },
    {
      enabled: start !== undefined && end !== undefined,
      staleTime: 1000 * 30,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useEvents',
          data: { start, end },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function updateEvent(event: Event, update: Event) {
  return axios
    .patch(`${API_URL}/events/${event?.uuid}/`, update)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'updateEvent',
        data: { event: event.uuid, update: update },
      });
      throw error;
    });
}

function useUpdateEvent(
  event: Event,
  update: Event,
  onSuccess?: (timeSlot: Event) => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return updateEvent(event, update);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('events');
        if (onSuccess) onSuccess(data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateEvent',
          data: { event, update },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function getRescheduleEvent(token?: string): Promise<EventLog> {
  return axios
    .get(`${API_URL}/reschedule/${token}/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getRescheduleEvent',
        data: { token },
      });
      throw error;
    });
}

function rescheduleEvent(props: { token?: string; eventLog?: EventLog }): Promise<EventLog> {
  const { token, eventLog } = props;
  return axios
    .patch(`${API_URL}/reschedule/${token}/`, eventLog)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'updateEventLog',
        data: { token, eventLog },
      });
      throw error;
    });
}

function useCreateEvent(event: Event, onSuccess?: (event: Event) => void, onError?: (error?: any) => void) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.post(`${API_URL}/events/`, event);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('events');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useCreateEvent',
          data: { event },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function importEvent(
  event: Event,
  client: Client,
  provider: Provider,
  providerService?: ProviderService,
  subscription?: ClientSubscription
) {
  return axios
    .post(`${API_URL}/events/${event?.uuid}/import/`, {
      client,
      provider,
      provider_service: providerService,
      subscription,
    })
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'importEvent',
        data: { event, client: client.uuid, provider, providerService, subscription },
      });
      throw error;
    });
}

function useDeleteEvent(props: {
  event: Event;
  date?: Date;
  following?: Date;
  all?: Date;
  onSuccess?: (timeSlot: Event) => void;
  onError?: (error?: any) => void;
}) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      let url = `${API_URL}/events/${props.event?.uuid}/?`;
      if (props.date) url += `date=${props.date.toISOString()}`;
      else if (props.following) url += `following=${props.following?.toISOString()}`;
      else if (props.all) url += `all=${props.all?.toISOString()}`;

      return axios.delete(url);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('events');
        if (props.onSuccess) props.onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateEvent',
          data: { event: props.event.uuid, date: props.date, following: props.following },
        });
        if (props.onError) props.onError(error);
        else throw error;
      },
    }
  );
}

function useEventsLog(start?: Date, end?: Date, client?: Client, onError?: (error?: any) => void) {
  return useQuery(
    ['events-log', { start, end }, client?.uuid],
    async (): Promise<Array<EventLog>> => {
      const { data } = await axios.get(
        `${API_URL}/events-log/?start=${start?.toISOString()}&end=${end?.toISOString()}${
          client ? `&client=${client.uuid}` : ''
        }`
      );
      return data;
    },
    {
      enabled: start !== undefined && end !== undefined,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useEventsLog',
          data: { start, end },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function getEventLog(props: { event?: Event; start?: Date; uuid?: uuid }) {
  return props.uuid
    ? axios.get(`${API_URL}/events-log/${props.uuid}/`).then((res) => res.data)
    : axios.get(`${API_URL}/events-log/?event=${props.event?.uuid}&start=${props.start?.toISOString()}`).then((res) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) return res.data[0];
        else return undefined;
      });
}

function updateEventLog(eventLog: EventLog, update?: EventLog): Promise<EventLog> {
  return axios
    .patch(`${API_URL}/events-log/${eventLog?.uuid}/`, update)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'updateEventLog',
        data: { eventLog, update },
      });
      throw error;
    });
}

function createEventLog(eventLog: EventLog): Promise<EventLog> {
  return axios
    .post(`${API_URL}/events-log/`, eventLog)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'createEventLog',
        data: { eventLog },
      });
      throw error;
    });
}

function useClientProviders(enabled?: boolean, onError?: (error?: any) => void) {
  const { currentUser } = useAuth();
  return useQuery(
    'client-providers',
    async (): Promise<Array<ClientProvider>> => {
      const { data } = await axios.get(`${CLIENT_PROVIDER_URL}`);
      return data;
    },
    {
      enabled: enabled !== false && currentUser !== undefined,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useClientProviders',
          data: { enabled },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useClientProvider(id?: ID, onError?: (error?: any) => void) {
  return useQuery(
    ['client-providers', id],
    async (): Promise<ClientProvider> => {
      const { data } = await axios.get(`${API_URL}/client-providers/${id}`);
      return data;
    },
    {
      enabled: !!id,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useClientProvider',
          data: { id },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useUpdateClientProvider(
  clientProvider: ClientProvider | undefined,
  update: ClientProvider,
  onSuccess?: (client_provider: ClientProvider) => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.patch(`${API_URL}/client-providers/${clientProvider?.uuid}/`, update);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('client-providers');
        queryClient.invalidateQueries('clients');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateClientProvider',
          data: { clientProvider, update },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

const getOnboarding = (inviteId: string | undefined, onError?: (error?: any) => void) =>
  axios
    .get(`${API_URL}/onboarding/${inviteId}`)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getOnboarding',
      });
      if (onError) onError(error);
      else throw error;
    });

function useOnboarding(inviteId: string | undefined, onError?: (error?: any) => void) {
  return useQuery(
    'onboarding',
    async (): Promise<ClientOnboarding> => {
      const { data } = await axios.get(`${API_URL}/onboarding/${inviteId}`);
      return data;
    },
    {
      enabled: inviteId !== undefined,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useOnboarding',
          data: { inviteId },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function updateOnboarding(
  inviteId: string | undefined,
  update: any,
  onSuccess?: (onboarding: ClientOnboarding) => void,
  onError?: (err: any) => void
) {
  return axios
    .patch(`${API_URL}/onboarding/${inviteId}/`, update)
    .then((d: AxiosResponse<ClientOnboarding>) => {
      if (onSuccess) onSuccess(d.data);
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'updateOnboarding',
        data: { inviteId, update },
      });
      if (onError) onError(error);
      else throw error;
    });
}

function useAvailableTimeslot(props: {
  serviceUuid: uuid;
  start: Date;
  end: Date;
  priceMax?: number;
  startTime?: string;
  timezone?: string;
  providersUuid?: uuid[];
  recurringOnly?: boolean;
  clientUuid?: uuid;
  anyProvider?: boolean;
  onError?: (error?: any) => void;
  [key: string]: any;
}) {
  const {
    serviceUuid,
    start,
    end,
    priceMax,
    startTime,
    timezone,
    providersUuid,
    recurringOnly,
    clientUuid,
    anyProvider,
    onError,
    ...otherParams
  } = props;
  return useQuery(
    [
      'timeslot-availability',
      serviceUuid,
      clientUuid,
      start,
      end,
      priceMax,
      startTime,
      timezone,
      providersUuid,
      recurringOnly,
      anyProvider,
      otherParams,
    ],
    async (): Promise<AvailabilityList> => {
      // let url = `${SERVICE_TIMESLOT_URL(serviceUuid)}available/?start=${start.toISOString()}&end=${end.toISOString()}`;
      // if (providersUuid) url += `&providers=${providersUuid.join(',')}`;
      // if (priceMax) url += `&price__lte=${priceMax}`;
      // if (startTime) url += `&start_time__gte=${startTime}`;
      // if (timezone) url += `&tz=${timezone}`;
      // if (recurringOnly) url += `&recurring=${recurringOnly}`;
      // if (clientUuid) url += `&client=${clientUuid}`;
      // return await axios.get(url).then((d: AxiosResponse<AvailabilityList>) => d.data);

      let params = {
        start: start,
        end: end,
        providers: providersUuid,
        price__lte: priceMax,
        start_time__gte: startTime,
        tz: timezone,
        recurring: recurringOnly,
        client: clientUuid,
        any_provider: anyProvider,
        ...otherParams,
      };
      return await axios
        .post(`${SERVICE_TIMESLOT_URL(serviceUuid)}available/`, params)
        .then((d: AxiosResponse<AvailabilityList>) => d.data);
    },
    {
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useAvailableTimeslot',
          data: { serviceUuid, start, end, priceMax, startTime, timezone, providersUuid, recurringOnly, clientUuid },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function availableTimeslotCount(props: { serviceUuid: uuid; providersUuid?: uuid[]; start: Date; end: Date }) {
  const { serviceUuid, providersUuid, start, end } = props;
  return axios
    .get(`${SERVICE_TIMESLOT_URL(serviceUuid)}available/count/`, {
      params: { providers: providersUuid, start: start, end: end },
    })
    .then((res) => {
      return res.data?.count;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'availableTimeslotCount',
        data: { serviceUuid, providersUuid, start, end },
      });
      throw error;
    });
}

const holdAvailableTimeslot = (
  serviceUuid: uuid,
  timeslotUuid: uuid,
  clientUuid: uuid,
  onError?: (error?: any) => void
) =>
  axios.patch(`${SERVICE_TIMESLOT_URL(serviceUuid)}${timeslotUuid}/hold/?client=${clientUuid}`).catch((error) => {
    monitoringCaptureError(error, {
      module: 'api',
      func: 'holdAvailableTimeslot',
      data: { serviceUuid, timeslotUuid, clientUuid },
    });
    if (onError) onError(error);
    else throw error;
  });

function useAvailableTimeslotHold(
  serviceUuid: uuid,
  timeslotUuid: uuid,
  clientUuid: uuid,
  onSuccess?: () => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return holdAvailableTimeslot(serviceUuid, timeslotUuid, clientUuid);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('timeslot-availability');
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        queryClient.invalidateQueries('timeslot-availability');
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateEvent',
          data: { serviceUuid, timeslotUuid, clientUuid },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

const releaseAvailableTimeslot = (
  serviceUuid: uuid,
  timeslotUuid: uuid,
  clientUuid: uuid,
  onError?: (error?: any) => void
) =>
  axios.patch(`${SERVICE_TIMESLOT_URL(serviceUuid)}${timeslotUuid}/release/?client=${clientUuid}`).catch((error) => {
    monitoringCaptureError(error, {
      module: 'api',
      func: 'releaseAvailableTimeslot',
      data: { serviceUuid, timeslotUuid, clientUuid },
    });
    if (onError) onError(error);
    else throw error;
  });

function useAvailableTimeslotRelease(
  serviceUuid: uuid,
  timeslotUuid: uuid,
  clientUuid: uuid,
  onSuccess?: () => void,
  onError?: (error?: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return releaseAvailableTimeslot(serviceUuid, timeslotUuid, clientUuid);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('timeslot-availability');
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        queryClient.invalidateQueries('timeslot-availability');
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useAvailableTimeslotRelease',
          data: { serviceUuid, timeslotUuid, clientUuid },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

const bookAvailableTimeslots = (
  serviceUuid: uuid,
  timeslotUuids: Array<uuid>,
  clientUuid: uuid,
  oneOffUuid?: uuid,
  subscriptionUuid?: uuid,
  onError?: (error?: any) => void
) =>
  axios
    .post(`${SERVICE_TIMESLOT_URL(serviceUuid)}book/`, {
      client: clientUuid,
      slots: timeslotUuids,
      oneoff: oneOffUuid,
      subscription: subscriptionUuid,
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'bookAvailableTimeslots',
        data: { serviceUuid, timeslotUuids, clientUuid, oneOffUuid },
      });
      if (onError) onError(error);
      else throw error;
    });

const existsFormTemplate = (id: uuid | string, onError?: (error?: any) => void): Promise<boolean | void> =>
  axios
    .get<IWizFormTemplateExists>(`${FORM_TEMPLATE_URL(id)}exists/`)
    .then((res) => !!res.data?.exists)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'existsFormTemplate',
        data: { id },
      });
      if (onError) onError(error);
      else throw error;
    });

const getFormTemplate = (id: uuid | string, onError?: (error?: any) => void): Promise<IWizFormTemplate> =>
  existsFormTemplate(id)
    .then((exists: boolean | void) => {
      if (exists) return axios.get(`${FORM_TEMPLATE_URL(id)}`).then((res) => res.data);
      else return undefined;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getFormTemplate',
        data: { id },
      });
      if (onError) onError(error);
      else throw error;
    });

const getFormTemplates = (onError?: (error?: any) => void): Promise<Array<IWizFormTemplate>> => {
  return axios
    .get(FORM_TEMPLATES_URL)
    .then((res) => res.data)

    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getFormTemplates',
      });
      if (onError) onError(error);
      else throw error;
    });
};

function useFormTemplates(props: {
  provider?: Provider;
  searchTerm?: string;
  enabled?: boolean;
  onError?: (error?: any) => void;
}) {
  return useQuery(
    ['form-templates', props.provider?.uuid, props.searchTerm || ''],
    async (): Promise<Array<IWizFormTemplate>> => {
      const { data } = await axios.get(`${FORM_TEMPLATES_URL}?search=${props.searchTerm || ''}`);
      return data;
    },
    {
      enabled: props.enabled !== false,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useFormTemplates',
          data: { client: props.provider?.uuid, searchTerm: props.searchTerm, enabled: props.enabled },
        });
        if (props.onError) props.onError(error);
        else throw error;
      },
    }
  );
}

const inviteFormTemplate = (
  formTemplateUuid: uuid,
  clientUuid: ID,
  details?: any,
  onError?: (error?: any) => void
): Promise<ClientInvite | undefined> => {
  return axios
    .post(`${FORM_TEMPLATE_URL(formTemplateUuid)}invite/?client_uuid=${clientUuid}`, details)
    .then((response: AxiosResponse<ClientInvite>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'client._requests',
        func: 'inviteFormTemplate',
        data: { clientUuid, formTemplateUuid, details },
      });
      if (onError) onError(error);
      else throw error;
      return undefined;
    });
};

const createForm = (form: IWizForm, onError?: (error?: any) => void): Promise<IWizForm> =>
  axios
    .post(`${FORM_URL}`, form)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'createForm',
        data: { form },
      });
      if (onError) onError(error);
      else throw error;
    });

const updateForm = (uuid: uuid, form: IWizForm, onError?: (error?: any) => void): Promise<IWizForm> =>
  axios
    .patch(`${FORM_URL}${uuid}/`, form)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'updateForm',
        data: { uuid, form },
      });
      if (onError) onError(error);
      else throw error;
    });

const getIntake = (inviteId: uuid, onError?: (error?: any) => void) =>
  axios
    .get(`${API_URL}/intake/${inviteId}/`)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'existsFormTemplate',
        data: { inviteId },
      });
      if (onError) onError(error);
      else throw error;
    });

const postIntake = (inviteId: uuid | string, formData: any, onError?: (error?: any) => void) =>
  axios.post(`${API_URL}/intake/${inviteId}/`, formData).catch((error) => {
    monitoringCaptureError(error, {
      module: 'api',
      func: 'postIntake',
      data: { inviteId, formData },
    });
    if (onError) onError(error);
    else throw error;
  });

function useNotes(client?: Client, searchTerm?: string, enabled?: boolean, onError?: (error?: any) => void) {
  return useQuery(
    ['notes', client?.uuid, searchTerm || ''],
    async (): Promise<Array<Note>> => {
      const { data } = await axios.get(`${API_URL}/clients/${client?.uuid}/notes/?search=${searchTerm || ''}`);
      return data;
    },
    {
      enabled: enabled !== false && !!client,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useNotes',
          data: { client: client?.uuid, searchTerm, enabled },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function getNote(client?: Client, note_uuid?: uuid, onError?: (error?: any) => void) {
  return axios.get(`${API_URL}/clients/${client?.uuid}/notes/${note_uuid}/`).then((res) => res.data);
}

function useCreateNote(client: Client, onSuccess?: (note: Note) => void, onError?: (error?: any) => void) {
  const queryClient = useQueryClient();
  return useMutation(
    (note: Note) => {
      return axios.post(`${API_URL}/clients/${client.uuid}/notes/`, note);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('notes');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useCreateNote',
          data: { client: client?.uuid },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useUpdateNote(client: Client, onSuccess?: (note: Note) => void, onError?: (error?: any) => void) {
  const queryClient = useQueryClient();
  return useMutation(
    (note?: Note) => {
      return axios.patch(`${API_URL}/clients/${client.uuid}/notes/${note?.uuid}/`, note);
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('notes');
        if (onSuccess) onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateNote',
          data: { client: client?.uuid },
        });
        if (onError) onError(error);
        else throw error;
      },
    }
  );
}

function useDocuments(props: {
  client?: Client;
  provider?: Provider;
  searchTerm?: string;
  enabled?: boolean;
  onError?: (error?: any) => void;
}) {
  return useQuery(
    ['documents', props.client?.uuid, props.searchTerm || ''],
    async (): Promise<Array<Document>> => {
      const { data } = await axios.get(
        props.client
          ? `${API_URL}/clients/${props.client?.uuid}/documents/?search=${props.searchTerm || ''}`
          : `${API_URL}/providers/${props.provider?.uuid}/documents/?search=${props.searchTerm || ''}`
      );
      return data;
    },
    {
      enabled: props.enabled !== false && (!!props.client || !!props.provider),
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useDocuments',
          data: { client: props.client?.uuid, searchTerm: props.searchTerm, enabled: props.enabled },
        });
        if (props.onError) props.onError(error);
        else throw error;
      },
    }
  );
}

function createDocument(props: {
  doc: Document;
  client?: Client;
  provider?: Provider;
  onError?: (error?: any) => void;
}) {
  const formData = new FormData();
  const doc = props.doc;
  if (doc.file) formData.append('file', doc.file);
  if (doc.description) formData.append('description', doc.description);
  if (doc.file?.name) formData.append('filename', doc.file.name);
  if (doc.file?.type) formData.append('filetype', doc.file.type);
  if (doc.file?.size) formData.append('filesize', `${doc.file.size}`);
  if (doc.file?.lastModified) formData.append('filemodified', `${new Date(doc.file.lastModified).toISOString()}`);
  if (doc.note) formData.append('note', doc.note);
  if (doc.provider?.uuid?.toString()) formData.append('provider_uuid', doc.provider?.uuid?.toString() || '');
  if (doc.client?.uuid?.toString()) formData.append('client_uuid', doc.client?.uuid?.toString() || '');

  return axios.post(
    props.client
      ? `${API_URL}/clients/${props.client.uuid}/documents/`
      : `${API_URL}/providers/${props.provider?.uuid}/documents/`,
    formData
  );
}

function useCreateDocument(props: {
  doc: Document;
  client?: Client;
  provider?: Provider;
  onSuccess?: (doc: Document) => void;
  onError?: (error?: any) => void;
}) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return createDocument({ doc: props.doc, client: props.client, provider: props.provider });
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('documents');
        if (props.onSuccess) props.onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useCreateDocument',
          data: { client: props.client?.uuid, doc: props.doc },
        });
        if (props.onError) props.onError(error);
        else throw error;
      },
    }
  );
}

function updateDocument(props: { doc?: Document; client?: Client; provider?: Provider }) {
  return axios.patch(
    props.client
      ? `${API_URL}/clients/${props.client.uuid}/documents/${props.doc?.uuid}/`
      : `${API_URL}/providers/${props.provider?.uuid}/documents/${props.doc?.uuid}/`,
    props.doc
  );
}

function useUpdateDocument(props: {
  client?: Client;
  provider?: Provider;
  onSuccess?: (doc: Document) => void;
  onError?: (error?: any) => void;
}) {
  const queryClient = useQueryClient();
  return useMutation(
    (doc?: Document) => {
      return updateDocument({ doc: doc, client: props.client, provider: props.provider });
    },
    {
      onSuccess: (updatedItem) => {
        queryClient.invalidateQueries('documents');
        if (props.onSuccess) props.onSuccess(updatedItem.data);
      },
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useUpdateDocument',
          data: { client: props.client?.uuid },
        });
        if (props.onError) props.onError(error);
        else throw error;
      },
    }
  );
}

function getDocumentThumbnailUrl(props: {
  doc?: Document;
  client?: Client;
  provider?: Provider;
  onError?: (error?: any) => void;
}) {
  return axios
    .get(
      props.client
        ? `${API_URL}/clients/${props.client.uuid}/documents/${props.doc?.uuid}/thumbnail/`
        : `${API_URL}/providers/${props.provider?.uuid}/documents/${props.doc?.uuid}/thumbnail/`,
      { responseType: 'arraybuffer' }
    )
    .then((response) => {
      const contentType = response.headers['content-type'];
      const data = new Blob([response.data], { type: contentType });
      const url = URL.createObjectURL(data);
      return url;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getDocumentThumbnailUrl',
        data: { client: props.client?.uuid, doc: props.doc?.uuid },
      });
      if (props.onError) props.onError(error);
      else throw error;
    });
}

// function downloadDocument(client: Client, doc?: Document) {
//   return axios
//     .get(`${API_URL}/clients/${client.uuid}/documents/${doc?.uuid}/download/`, {
//       responseType: 'blob',
//     })
//     .then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', doc?.filename || 'file');
//       document.body.appendChild(link);
//       link.click();
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// }

// function openDocument(client: Client, doc?: Document, newWindow?: Window | null) {
//   return axios
//     .get(`${API_URL}/clients/${client.uuid}/documents/${doc?.uuid}/open/`, {
//       responseType: 'blob',
//     })
//     .then((response) => {
//       if (newWindow) {
//         const objectURL = URL.createObjectURL(new Blob([response.data], { type: doc?.filetype }));
//         newWindow.location.href = objectURL;
//         newWindow.document.title = doc?.filename || 'file';
//       }
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// }

function openDocument(props: {
  doc?: Document;
  client?: Client;
  provider?: Provider;
  newWindow?: Window | null;
  onError?: (error?: any) => void;
}) {
  return axios
    .get(
      props.client
        ? `${API_URL}/clients/${props.client.uuid}/documents/${props.doc?.uuid}/token/`
        : `${API_URL}/providers/${props.provider?.uuid}/documents/${props.doc?.uuid}/token/`
    )
    .then((response) => {
      const token = response.data.token;
      const url = `${API_URL}/documents/open/${token}/${props.doc?.filename}`;
      if (props.newWindow) props.newWindow.location.href = url;
      else window.open(url, '_blank', 'noreferrer');
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'openDocument',
        data: { client: props.client?.uuid, doc: props.doc?.uuid },
      });
      if (props.onError) props.onError(error);
      else throw error;
    });
}

function downloadDocument(props: {
  doc?: Document;
  client?: Client;
  provider?: Provider;
  onError?: (error?: any) => void;
}) {
  return axios
    .get(
      props.client
        ? `${API_URL}/clients/${props.client.uuid}/documents/${props.doc?.uuid}/token/`
        : `${API_URL}/providers/${props.provider?.uuid}/documents/${props.doc?.uuid}/token/`
    )
    .then((response) => {
      const token = response.data.token;
      const url = `${API_URL}/documents/download/${token}/${props.doc?.filename}`;
      window.open(url, '_self', 'noreferrer');
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'downloadDocument',
        data: { client: props.client?.uuid, doc: props.doc?.uuid },
      });
      if (props.onError) props.onError(error);
      else throw error;
    });
}

function downloadInvoice(props: {
  invoice: IInvoice;
  client?: Client;
  provider?: Provider;
  onError?: (error?: any) => void;
}) {
  const url = props.client
    ? `${API_URL}/clients/${props.client.uuid}/invoices/${props.invoice.uuid}/pdf/`
    : `${API_URL}/providers/${props.provider?.uuid}/invoices/${props.invoice.uuid}/pdf/`;
  // window.open(url, '_self', 'noreferrer');
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, 'Invoice-' + props.invoice.invoice_number + '.pdf');
    });
}

const getInvoices = (filters: any): Promise<Array<IInvoice>> => {
  return axios
    .get(`${API_URL}/invoices/`, { params: filters })
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getInvoices',
      });
      throw error;
    });
};

const getStripeInvoices = (filters: any): Promise<Array<IInvoice>> => {
  return axios
    .get(`${API_URL}/sinvoices/`, { params: filters })
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'getInvoices',
      });
      throw error;
    });
};

function downloadInvoiceReceipt(props: {
  invoice: IInvoice;
  client?: Client;
  provider?: Provider;
  onError?: (error?: any) => void;
}) {
  const url = props.client
    ? `${API_URL}/clients/${props.client.uuid}/invoices/${props.invoice.uuid}/receipt-pdf/`
    : `${API_URL}/providers/${props.provider?.uuid}/invoices/${props.invoice.uuid}/receipt-pdf/`;
  // window.open(url, '_self', 'noreferrer');
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, 'Receipt-' + props.invoice.invoice_number + '.pdf');
    });
}

function deleteDocument(props: {
  doc?: Document;
  client?: Client;
  provider?: Provider;
  onSuccess?: () => void;
  onError?: (error?: any) => void;
}) {
  return axios
    .delete(
      props.client
        ? `${API_URL}/clients/${props.client.uuid}/documents/${props.doc?.uuid}/`
        : `${API_URL}/providers/${props.provider?.uuid}/documents/${props.doc?.uuid}/`
    )
    .then((response) => {
      props.onSuccess && props.onSuccess();
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'deleteDocument',
        data: { client: props.client?.uuid, provider: props.provider?.uuid, doc: props.doc?.uuid },
      });
      if (props.onError) props.onError(error);
      else throw error;
    });
}

function useIcsIntegrations(props: { provider?: Provider; onError?: (error?: any) => void }) {
  return useQuery(
    ['ics-integrations', props?.provider?.uuid],
    async (): Promise<Array<IIcsIntegration>> => {
      return await axios.get(`${API_URL}/integrations/ics/`).then((res) => res.data);
    },
    {
      enabled: !!props?.provider,
      onError: (error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'useIcsIntegrations',
          data: { provider: props.provider?.uuid },
        });
        if (props?.onError) props?.onError(error);
        else throw error;
      },
    }
  );
}

const createIcsIntegration = (props: {
  ics: IIcsIntegration;
  onError?: (error?: any) => void;
}): Promise<IIcsIntegration> =>
  axios
    .post(`${API_URL}/integrations/ics/`, props.ics)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'createIcsIntegration',
        data: { ics: props.ics },
      });
      if (props.onError) props.onError(error);
      else throw error;
    });

const updateIcsIntegration = (props: {
  ics: IIcsIntegration;
  onError?: (error?: any) => void;
}): Promise<IIcsIntegration> =>
  axios
    .patch(`${API_URL}/integrations/ics/${props.ics.uuid}`, props.ics)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'updateIcsIntegration',
        data: { ics: props.ics },
      });
      if (props.onError) props.onError(error);
      else throw error;
    });

const refreshIcsIntegration = (props: {
  ics: IIcsIntegration;
  onError?: (error?: any) => void;
}): Promise<IIcsIntegration> =>
  axios
    .get(`${API_URL}/integrations/ics/${props.ics.uuid}/refresh/`, props.ics)
    .then((res) => res.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'refreshIcsIntegration',
        data: { ics: props.ics },
      });
      if (props.onError) props.onError(error);
      else throw error;
    });

const deleteIcsIntegration = (props: { ics: IIcsIntegration; onError?: (error?: any) => void }): Promise<boolean> =>
  axios
    .delete(`${API_URL}/integrations/ics/${props.ics.uuid}`, props.ics)
    .then((res) => {
      return true;
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'api',
        func: 'deleteIcsIntegration',
        data: { ics: props.ics },
      });
      if (props.onError) props.onError(error);
      return false;
    });

const ping = async () => {
  const ret = await axios.get(`${API_URL}/ping`);
  return ret && ret.status == 200;
};

export {
  useServices,
  useService,
  useProviderServices,
  getProviderServicesCount,
  setupProviderServices,
  getProviderService,
  useUpdateProviderService,
  useCreateProviderService,
  useProviderTimeSlots,
  useUpdateProviderTimeSlot,
  useCreateProviderTimeSlot,
  useProviderTimeSlotServices,
  setupProviderTimeSlot,
  getProviderTimeSlotCount,
  useProviderMe,
  useUpdateProvider,
  useClients,
  getClientsCount,
  useClientProviders,
  useGuardians,
  useProviders,
  useProvider,
  getProviderPaymentEnabled,
  useAges,
  useExpertises,
  useConcerns,
  useLanguages,
  usePostalCodes,
  getPostalCode,
  useTimeSlots,
  useUpdateTimeSlot,
  useCreateTimeSlot,
  getEvents,
  useEvents,
  getEventLog,
  updateEvent,
  useUpdateEvent,
  useCreateEvent,
  importEvent,
  useDeleteEvent,
  getRescheduleEvent,
  rescheduleEvent,
  useEventsLog,
  updateEventLog,
  createEventLog,
  useClientProvider,
  useUpdateClientProvider,
  getOnboarding,
  useOnboarding,
  updateOnboarding,
  useAvailableTimeslot,
  availableTimeslotCount,
  useAvailableTimeslotHold,
  useAvailableTimeslotRelease,
  holdAvailableTimeslot,
  releaseAvailableTimeslot,
  bookAvailableTimeslots,
  existsFormTemplate,
  getFormTemplate,
  getFormTemplates,
  useFormTemplates,
  inviteFormTemplate,
  createForm,
  updateForm,
  getIntake,
  postIntake,
  getNote,
  useNotes,
  useCreateNote,
  useUpdateNote,
  useDocuments,
  createDocument,
  useCreateDocument,
  updateDocument,
  useUpdateDocument,
  getDocumentThumbnailUrl,
  downloadDocument,
  openDocument,
  deleteDocument,
  downloadInvoice,
  downloadInvoiceReceipt,
  useClinic,
  useIcsIntegrations,
  createIcsIntegration,
  updateIcsIntegration,
  deleteIcsIntegration,
  refreshIcsIntegration,
  ping,
  getInvoices,
  getStripeInvoices,
};
