import axios from 'axios';
import { AuthModel, UserModel } from './_models';
import { API_URL } from '../../../helpers/api';
import { monitoringCaptureError } from '../../../helpers/monitoring';
import { Provider } from '../../../helpers/models';

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/me/`;
export const AUTHCHECK_URL = `${API_URL}/authcheck/`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/pwd-rst/`;
export const PASSWORD_RESET_URL = `${API_URL}/pwd-rst/confirm/`;
export const PASSWORD_TOKEN_VALID_URL = `${API_URL}/pwd-rst/validate_token/`;
export const PROVIDER_PWD_RESET_URL = `${API_URL}/provider-pwd`;

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(
    LOGIN_URL,
    {},
    {
      auth: {
        username: username.toLowerCase(),
        password,
      },
    }
  );
}

// Server should return AuthModel
export function register(
  username: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios
    .post<AuthModel>(REGISTER_URL, {
      username: username.toLowerCase(),
      first_name: firstname,
      last_name: lastname,
      password,
      password_confirmation,
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'auth._requests',
        func: 'register',
        data: { username, firstname, lastname },
      });
      throw error;
    });
}

export function setupProviderPassword(password: string, password_confirmation: string, inviteId: string | undefined) {
  return axios
    .post<AuthModel>(PROVIDER_PWD_RESET_URL, {
      password,
      password_confirmation,
      inviteId,
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'auth._requests',
        func: 'provider-pwd',
        data: { inviteId },
      });
      throw error;
    });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, action?: string, inviting_provider?: Provider) {
  return axios
    .post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
      email,
      action,
      inviting_provider: inviting_provider?.uuid,
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'auth._requests',
        func: 'requestPassword',
        data: { email },
      });
      throw error;
    });
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL).catch((error) => {
    monitoringCaptureError(error, {
      module: 'auth._requests',
      func: 'getUserByToken',
    });
    throw error;
  });
}

export async function checkUserAuthenticated() {
  try {
    const ret = await axios.get(AUTHCHECK_URL);
    return ret?.data?.status || false;
  } catch (error) {
    return false;
  }
}

export function passwordReset(token: string, password: string) {
  return axios
    .post(PASSWORD_RESET_URL, {
      token,
      password,
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'auth._requests',
        func: 'requestPassword',
        data: { passwordReset },
      });
      throw error;
    });
}

export function passwordTokenValid(token: string) {
  return axios
    .post(PASSWORD_TOKEN_VALID_URL, {
      token,
    })
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'auth._requests',
        func: 'requestPassword',
        data: { passwordReset },
      });
      throw error;
    });
}
