import React from 'react';
import Form from 'react-bootstrap/Form';
import {
  ariaDescribedByIds,
  enumOptionsDeselectValue,
  enumOptionsIsSelected,
  enumOptionsSelectValue,
  enumOptionsValueForIndex,
  optionId,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from '@rjsf/utils';
import moment from 'moment';

function WizCheckboxesViewerWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  id,
  disabled,
  options,
  value,
  autofocus,
  readonly,
  required,
  onChange,
  onBlur,
  onFocus,
}: WidgetProps<T, S, F>) {
  const { enumOptions, enumDisabled, inline, emptyValue } = options;
  const checkboxesValues = Array.isArray(value) ? value : [value];

  const _onChange =
    (index: number) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      if (checked) {
        onChange(enumOptionsSelectValue<S>(index, checkboxesValues, enumOptions));
      } else {
        onChange(enumOptionsDeselectValue<S>(index, checkboxesValues, enumOptions));
      }
    };

  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue));
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onFocus(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue));

  return (
    <Form.Group>
      <ul>
        {Array.isArray(enumOptions) &&
          enumOptions.map((option, index: number) => {
            const checked = enumOptionsIsSelected<S>(option.value, checkboxesValues);
            const itemDisabled = Array.isArray(enumDisabled) && enumDisabled.indexOf(option.value) !== -1;

            return checked ? <li key={`form-group-${index}`}>{option.label}</li> : '';
          })}
      </ul>
    </Form.Group>
  );
}

const InputViewerWidget: React.FC<WidgetProps> = (props) => {
  const { value } = props;
  if (typeof value === 'string') {
    return <p>{value}</p>;
  } else if (value instanceof Date) {
    return <p>{moment(value).format('LL')}</p>;
  } else {
    return <></>;
  }
};

const PasswordViewerWidget: React.FC<WidgetProps> = (props) => {
  const { value } = props;

  return <p>********</p>;
};

const EmptyWidget: React.FC<WidgetProps> = (props) => {
  return <></>;
};

export { WizCheckboxesViewerWidget, InputViewerWidget, PasswordViewerWidget, EmptyWidget };
