import clsx from 'clsx';
import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useDocuments } from '../../../../helpers/api';
import { DocumentCard } from '../../../widgets/components/DocumentCard';
import { FormCard } from '../../../widgets/components/FormCard';
import { Client, ClientProvider, Guardian } from '../core/_models';

type Props = {
  client?: Client;
  clientProvider?: ClientProvider;
  isLoading?: boolean;
};

const ClientDocuments: FC<Props> = (props) => {
  const [addNew, setAddNew] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const {
    refetch,
    status: documentsStatus,
    data: documents,
  } = useDocuments({ client: props.client, searchTerm, enabled: !!props.client });
  const forms = props.client?.forms;

  return (
    <>
      <div className="card shadow-sm mt-auto mb-5">
        <div className="card-header mobile-header">
          <h3 className="card-title">
            <span>Forms</span>
          </h3>
        </div>
        <div className="card-body">
          {props.client && (
            <>
              {forms && forms.length ? (
                forms.map((form) => <FormCard form={form} key={form.uuid} />)
              ) : (
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  No forms submitted
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="card shadow-sm mt-auto mb-5">
        <div className="card-header mobile-header">
          <h3 className="card-title">
            <span>Files</span>
          </h3>
          <div className="card-toolbar">
            <div className={clsx({ 'd-flex align-items-center position-relative my-1': true })}>
              <KTSVG path="/media/icons/duotune/general/gen021.svg" className="svg-icon-1 position-absolute ms-3" />
              <input
                type="text"
                data-kt-client-table-filter="search"
                className={clsx('form-control form-control-solid ps-12 btn-sm' /*, { 'w-250px ': !compact }*/)}
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                type="button"
                className="btn bg-transparent"
                style={{ marginLeft: '-40px', zIndex: '1' }}
                onClick={() => {
                  setSearchTerm('');
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <Button
              className="btn btn-outline btn-outline-primary btn-sm me-3"
              onClick={() => {
                setAddNew(true);
              }}
            >
              Upload File
            </Button>
          </div>
        </div>
        <div className="card-body">
          {props.client && (
            <>
              {addNew && (
                <DocumentCard
                  client={props.client}
                  provider={props.clientProvider?.provider}
                  onCancel={() => {
                    setAddNew(false);
                  }}
                  onSave={() => {
                    setAddNew(false);
                    refetch();
                  }}
                />
              )}
              <div className="row mt-4 mt-sm-0 mb-4">
                {documents &&
                  documents.map((doc) => (
                    <div className="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-6" key={doc.uuid}>
                      <DocumentCard
                        client={props.client!}
                        provider={props.clientProvider?.provider}
                        doc={doc}
                        onDelete={() => {
                          refetch();
                        }}
                        onSave={() => {
                          refetch();
                        }}
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { ClientDocuments };
