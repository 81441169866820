import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { getUserByToken, passwordReset, passwordTokenValid } from '../core/_requests';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import { useAuth } from '../core/Auth';
import YupPassword from 'yup-password';

import { Spinner } from 'react-bootstrap';
import { ForgotPassword } from './ForgotPassword';
import { monitoringCaptureError } from '../../../helpers/monitoring';
import { capitalize } from '../../../helpers/funcs';
YupPassword(Yup);

const passwordResetSchema = Yup.object().shape({
  password: Yup.string().password().required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Password and Confirm Password do not match'),
    }),
});

export function PasswordReset() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [tokenValid, setTokenValid] = useState<boolean | undefined>();
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const initialValues = {
    token: token,
    password: '',
    changepassword: '',
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (token)
      passwordTokenValid(token)
        .then((res) => {
          if (res.status === 200) {
            setTokenValid(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setTokenValid(false);
        });
  }, [token]);

  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const formik = useFormik({
    initialValues,
    validationSchema: passwordResetSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setFieldValue, setTouched }) => {
      setLoading(true);
      if (values.token) {
        passwordReset(values.token, values.password)
          .then((res) => {
            saveAuth(undefined);
            setSubmitting(false);
            setLoading(false);
            setStatus('Password reset successful');
            setHasErrors(false);
          })
          .catch((error) => {
            console.error(error);
            monitoringCaptureError(error, { module: 'PasswordReset' });
            saveAuth(undefined);
            setStatus(
              <span>
                Password reset has failed:
                <br />
                {error?.response.data?.password ? error?.response.data?.password.join(', ') : ''}
              </span>
            );
            setFieldValue('password', '');
            setFieldValue('changepassword', '');
            setTouched({}, false);
            setSubmitting(false);
            setLoading(false);
            setHasErrors(true);
          });
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return tokenValid === undefined ? (
    <>
      <div className="mb-10 text-center">
        <h1 className="text-dark mb-3">Password Reset</h1>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </>
  ) : tokenValid === false ? (
    <>
      <ForgotPassword
        title={
          <>
            <div className="text-center mb-10">
              {/* begin::Title */}
              <h1 className="text-dark mb-3">Password Reset</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className="text-gray-800 fw-bold fs-4 mt-5">The link has expired.</div>
              <div className="text-gray-800 fw-bold fs-4 mt-5">
                Please enter your email below to reset your password.
              </div>
              {/* end::Link */}
            </div>
          </>
        }
        noCancel={true}
        action="reset"
      />
    </>
  ) : (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className="mb-10 text-center">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Password Reset</h1>
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        {formik.status && (
          <div
            className={clsx('mb-lg-15 alert text-center', {
              'alert-success': hasErrors === false,
              'alert-danger': hasErrors === true,
            })}
          >
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {hasErrors !== false && (
          <>
            {/* begin::Form group Password */}
            <div className="mb-10 fv-row" data-kt-password-meter="true">
              <div className="mb-1">
                <label className="form-label fw-bolder text-dark fs-6">Password</label>
                <div className="position-relative mb-3">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="off"
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  <span
                    className={clsx('position-absolute top-50 end-0 translate-middle-y', {
                      'me-12  ': formik.touched.password && formik.errors.password,
                      'me-3': !(formik.touched.password && formik.errors.password),
                    })}
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container mb-3">
                    <div className="fv-help-block">
                      <span role="alert">{capitalize(formik.errors.password)}</span>
                    </div>
                  </div>
                )}
                {/* begin::Meter */}
                <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                  <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                  <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                  <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                  <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                </div>
                {/* end::Meter */}
              </div>
              <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className="fv-row">
              <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
              <div className="position-relative">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Password confirmation"
                  autoComplete="off"
                  {...formik.getFieldProps('changepassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    },
                    {
                      'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    }
                  )}
                />
                <span
                  className={clsx('position-absolute top-50 end-0 translate-middle-y', {
                    'me-12  ': formik.touched.changepassword && formik.errors.changepassword,
                    'me-3': !(formik.touched.changepassword && formik.errors.changepassword),
                  })}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{ cursor: 'pointer' }}
                >
                  <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                </span>
              </div>
            </div>
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{capitalize(formik.errors.changepassword)}</span>
                </div>
              </div>
            )}
            <div className="text-center mt-8">
              <button
                type="submit"
                id="kt_sign_up_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className="indicator-label">Submit</span>}
                {loading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_signup_form_cancel_button"
                  className="btn btn-lg btn-light-primary w-100 mb-5"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </>
        )}
        {/* end::Form group */}
        {hasErrors === false && (
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mb-5"
            >
              Go to Login Page
            </button>
          </Link>
        )}
      </form>
    </>
  );
}
