import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

type Props = {
  title: string;
  message?: string | JSX.Element;
  confirmButton?: string;
  confirmClassName?: string;
  cancelButton?: string;
  cancelClassName?: string;
  size?: 'sm' | 'lg' | 'xl' | undefined;
  onProceed?: (proceed: boolean) => void;
  noCancelButton?: boolean;
};

export function ConfirmDialog(props: Props) {
  let pressed = false;
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="static-modal">
          <Modal
            animation={false}
            show={true}
            onHide={() => {
              props.onProceed && props.onProceed(false);
              onClose();
            }}
            backdrop={true}
            centered
            size={props.size}
          >
            <Modal.Header>
              <Modal.Title>{props.title || 'Are you sure?'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
              {!props.noCancelButton && (
                <div
                  className={
                    (props.cancelClassName || 'btn btn-outline btn-outline-primary btn-sm me-5') +
                    (pressed ? ' disabled' : '')
                  }
                  onClick={() => {
                    if (pressed) return;
                    pressed = true;
                    props.onProceed && props.onProceed(false);
                    onClose();
                  }}
                >
                  {props.cancelButton || 'Cancel'}
                </div>
              )}
              <div
                className={(props.confirmClassName || 'btn btn-primary btn-sm') + (pressed ? ' disabled' : '')}
                onClick={() => {
                  if (pressed) return;
                  pressed = true;
                  props.onProceed && props.onProceed(true);
                  onClose();
                }}
              >
                {props.confirmButton || 'Yes'}
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      );
    },
    // title: 'Remove Attachment?',
    // message: `Are you sure you'd like to remove ${doc.filename}?`,
    // buttons: [
    //   {
    //     label: 'Yes',
    //     onClick: () => {
    //       deattachFile(doc);
    //     },
    //   },
    //   {
    //     label: 'No',
    //     onClick: () => {},
    //   },
    // ],
  });
}
