/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { AsideMenuItem } from './AsideMenuItem';
import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';
import { Client } from '../../../../app/helpers/models';

export function AsideMenuMain() {
  const { currentUser, currentProvider } = useAuth();
  return (
    <>
      <AsideMenuItem
        to="/home"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Home"
        fontIcon="bi-app-indicator"
      />
      {(currentUser?.guardian || currentUser?.client) && (
        <>
          {currentUser?.client && (
            <AsideMenuItem
              to={toAbsoluteUrl(`/clients/${currentUser?.client.uuid}/profile`)}
              title="Profile"
              fontIcon="bi-people"
              icon="/media/icons/duotune/communication/com013.svg"
            />
          )}
          {currentUser.guardian &&
            currentUser?.guardian.clients &&
            currentUser?.guardian.clients.length > 0 &&
            currentUser?.guardian.clients.map((guardianClient: Client) => (
              <AsideMenuItem
                to={toAbsoluteUrl(`/clients/${guardianClient.uuid}/profile`)}
                title={`${guardianClient.name} Profile`}
                fontIcon="bi-people"
                icon="/media/icons/duotune/communication/com013.svg"
                key={guardianClient.uuid}
              />
            ))}
          <AsideMenuItem
            to={toAbsoluteUrl(`/notes`)}
            title="Notes"
            fontIcon="bi-people"
            icon="/media/icons/duotune/communication/com013.svg"
          />
          <AsideMenuItem
            to={toAbsoluteUrl(`/documents`)}
            title="Documents"
            fontIcon="bi-people"
            icon="/media/icons/duotune/files/fil012.svg"
          />
        </>
      )}
      {(currentUser?.guardian?.payment_email || currentUser?.client?.is_payer) && (
        <>
          <AsideMenuItem
            to="/billing"
            title="Billing"
            fontIcon="bi-credit-card"
            icon="/media/icons/duotune/finance/fin002.svg"
          />
        </>
      )}
      {currentUser?.provider && (
        <>
          <AsideMenuItem
            to="/setup"
            title="Quick Setup"
            fontIcon="bi-calendar"
            icon="/media/icons/duotune/files/fil025.svg"
          />
          <AsideMenuItem
            to="/schedule"
            title="Schedule"
            fontIcon="bi-calendar"
            icon="/media/icons/duotune/general/gen014.svg"
          />
          <AsideMenuItem to="/booking" title="Booking" icon="/media/icons/duotune/wiz/schedule.svg" />
          <AsideMenuItem
            to="/clients"
            title="Clients"
            fontIcon="bi-people"
            icon="/media/icons/duotune/communication/com014.svg"
          />
          {currentUser?.provider?.is_admin && (
            <AsideMenuItem
              to="/financials/billing"
              title="Financials"
              fontIcon="bi-credit-card"
              icon="/media/icons/duotune/finance/fin002.svg"
            />
          )}
          <AsideMenuItem
            to="/documents"
            title="Documents"
            fontIcon="bi-chat-left"
            icon="/media/icons/duotune/files/fil012.svg"
          />
          <AsideMenuItem
            to="/referrals/"
            title="Referrals"
            fontIcon="bi-journal-text"
            icon="/media/icons/duotune/communication/com005.svg"
          />
          <AsideMenuItem
            to="/ComingSoon/chat"
            title="Chat"
            fontIcon="bi-chat-left"
            icon="/media/icons/duotune/communication/com012.svg"
            isComingSoon={true}
          />
          <AsideMenuItem
            to="//c.wiztherapy.com/"
            target="_blank"
            title="Community"
            fontIcon="fa-external-link-alt"
            suffixIcon="la-external-link-alt"
            icon="/media/icons/duotune/communication/com007.svg"
          />
          <div className="menu-item">
            <div className="menu-content py-1">
              <div className="separator border-light opacity-50 mx-1 my-0"></div>
            </div>
          </div>
          {/*<div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Settings</span>
        </div>
      </div>*/}
          <AsideMenuItem
            to={toAbsoluteUrl(`/providers/${currentProvider?.uuid}/profile`)}
            title="Profile"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/communication/com013.svg"
          />
          <AsideMenuItem
            to={toAbsoluteUrl(`/providers/${currentProvider?.uuid}/settings`)}
            title="Settings"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/coding/cod009.svg"
          />
          <AsideMenuItem
            to="/services"
            title="Services"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/abstract/abs027.svg"
          />
          <AsideMenuItem
            to="/availability"
            title="Availability"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen013.svg"
          />
          <AsideMenuItem
            to={toAbsoluteUrl(`/providers/${currentProvider?.uuid}/integrations`)}
            title="Integrations"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/coding/cod007.svg"
          />

          {/*<AsideMenuItem
        to="/preferences"
        title="Preferences"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/general/gen019.svg"
      />
      <AsideMenuItem
        to="/integrations"
        title="Integrations"
        icon="/media/icons/duotune/coding/cod007.svg"
        fontIcon="bi-person"
      />
      <AsideMenuItem
        to="/ComingSoon/billing"
        title="Billing"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/finance/fin002.svg"
        isComingSoon={true}
      />*/}

          <div className="menu-item">
            <div className="menu-content py-1">
              <div className="separator border-light opacity-50 mx-1 my-0"></div>
            </div>
          </div>
          <AsideMenuItem
            to="/clinic/profile"
            title="Clinic"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/finance/fin001.svg"
          />
          <AsideMenuItem
            to="/clinic/staff"
            title="Clinic Staff"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/communication/com014.svg"
          />
          <div className="menu-item">
            <div className="menu-content py-1">
              <div className="separator border-light opacity-50 mx-1 my-0"></div>
            </div>
          </div>
          <AsideMenuItem
            to="/ProvidersFAQ"
            title="Help & FAQ"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen046.svg"
          />
          <AsideMenuItem
            to="/ProviderSupport"
            title="Support"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen011.svg"
          />
          <AsideMenuItem
            to="/WhatsNew"
            title="What's New"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen002.svg"
          />
          <div style={{ height: '120px' }} />
        </>
      )}
    </>
  );
}
