const QUERIES = {
  USERS_LIST: 'users-list',
  CLIENTS_LIST: 'clients-list',
  CLIENT_PROVIDERS_LIST: 'client-providers-list',
  GUARDIANS_LIST: 'guardians-list',
  PROVIDERS_LIST: 'providers-list',
  CLINIC_PROVIDERS_LIST: 'clinics-providers-list',
  PROVIDER_CONNECTIONS_LIST: 'provider-connections-list',
  PROVIDER_ADD_CONNECTION_LIST: 'provider-add-connection-list',
  REFERRALS_LIST: 'referrals-list',
};

export { QUERIES };
