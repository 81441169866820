import { FC, ReactNode, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Interweave } from 'interweave';

type Props = {
  content: string;
  editMode: boolean;
  placeholder?: string | ReactNode;
  className?: string;
  formattingOptions?: string[];
  onChange: (newValue: string) => void;
};

const HtmlInput: FC<Props> = (props) => {
  const contentState = stateFromHTML(props.content);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const isContentEmpty =
    !props.content || props.content.length == 0 || ['<p></p>\n', '<p></p>', '<p><br></p>'].indexOf(props.content) != -1;

  useEffect(() => {
    const newContentState = stateFromHTML(props.content);
    setEditorState(EditorState.createWithContent(newContentState));
  }, []);

  return props.editMode ? (
    <Editor
      editorState={editorState}
      onEditorStateChange={(newEditorState) => {
        setEditorState(newEditorState);
        const newContent = stateToHTML(newEditorState.getCurrentContent());
        // setContent(newContent);
        props.onChange && props.onChange(newContent);
      }}
      toolbarClassName={''}
      editorClassName={''}
      wrapperClassName={'editor-box-wrapper'}
      toolbar={{
        options: props.formattingOptions || [
          'inline',
          'fontSize',
          'fontFamily',
          'blockType',
          'list',
          'emoji',
          'image',
          'link',
          'history',
          'textAlign',
        ], // 'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'
        inline: { options: ['bold', 'italic', 'underline'] }, // 'bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'
        blockType: { inDropdown: true },
      }}
    />
  ) : isContentEmpty ? (
    <div className={props.className || 'providerNotes'}>{props.placeholder}</div>
  ) : (
    <Interweave content={props.content} className={props.className || 'providerNotes'} />
  );
};

export { HtmlInput };
