import ReactDOM from 'react-dom';
import { FC } from 'react';
import React from 'react';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import 'url-search-params-polyfill';
// import { gapi } from 'gapi-script';

// Apps
//import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-day-picker/dist/style.css';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/modules/auth';
import {
  get_deployment,
  get_google_client_id,
  get_long_version,
  is_dev,
  is_fb_ads,
  is_fb_pixel,
  is_production,
  should_monitor,
} from './app/helpers/environment';
import * as Sentry from '@sentry/react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { Alert } from 'react-bootstrap';
import { I18nProvider } from './_metronic/i18n/i18nProvider';

if (should_monitor() || process.env.REACT_APP_FORCE_MONITORING) {
  Sentry.init({
    dsn: 'https://b6cf2432836b45d58d30ce3b30c2e33e@o4503905078018048.ingest.sentry.io/4503905079132160',
    integrations: [
      Sentry.browserTracingIntegration({
        tracingOrigins: ['api.wiztherapy.com', 'api-staging.wiztherapy.com', 'localhost'],
      }),
      // Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.feedbackIntegration({
        buttonLabel: 'Feedback',
        submitButtonLabel: 'Send Feedback',
        formTitle: 'Send Feedback',
        messagePlaceholder: 'Provide your feedback? Report a bug? Request a feature?',
        successMessageText: 'Thank you for your feedback!',
        showName: false,
        showEmail: false,
        useSentryUser: {
          email: 'email',
          name: 'name',
        },
        showBranding: false,
      }),
    ],
    release: get_long_version(),
    environment: get_deployment(),
    replaysSessionSampleRate: is_production() ? 0.2 : 0,
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: is_production() ? 0.8 : 0.5,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.wiztherapy\.com\/api/,
      /^https:\/\/api-staging\.wiztherapy\.com\/api/,
    ],
    profilesSampleRate: 1.0,
  });
} else {
  console.error('Running dev app - monitoring disabled');
}

try {
  const deployment = get_deployment();
  const Ga4Ids = { staging: 'G-7Y1ZKRWCT2', dev: 'G-EKM8H0660J', production: 'G-G7VLD8X57L' };
  ReactGA.initialize(Ga4Ids[deployment]);
} catch {
  console.error('Failed to setup GA4');
}

const urlParams = new URLSearchParams(window.location.search);

const wizCampaign = urlParams.get('wc') || urlParams.get('utm_wc');
const facebookPixel = urlParams.get('fp') || urlParams.get('utm_fp');
if (is_fb_ads(wizCampaign) || is_fb_pixel(facebookPixel)) {
  if (is_fb_pixel(facebookPixel)) ReactPixel.init(facebookPixel!);
  else ReactPixel.init('3626852507582177');
  ReactPixel.pageView();
}

// if (!is_dev()) {
//   gapi.load('client:auth2', () => {
//     gapi.client.init({
//       clientId: get_google_client_id(),
//       plugin_name: 'chat',
//     });
//   });
// }

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
const magicToken = urlParams.get('magic');
setupAxios({ axios, magicToken });

Chart.register(...registerables);

const queryClient = new QueryClient();

const alertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '5px',
  transition: transitions.SCALE,
};

const WtAlertTemplate = (props: any) => {
  const variant = props.options.type === 'error' ? 'danger' : props.options.type;
  return (
    <Alert variant={variant} className="mb-5" style={{ ...props.style }} dismissible onClick={props.close}>
      <span style={{ flex: 2 }}>{props.message}</span>
    </Alert>
  );
};

ReactDOM.render(
  <AlertProvider template={WtAlertTemplate} {...alertOptions}>
    <QueryClientProvider client={queryClient}>
      {/* <MetronicI18nProvider> */}
      {/* <I18nProvider> */}
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
      {/* </I18nProvider> */}

      {/* </MetronicI18nProvider> */}
      {process.env.REACT_APP_QUERY_DEBUG === 'true' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </AlertProvider>,
  document.getElementById('root')
);
