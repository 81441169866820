import { ElementType, FC, useEffect, useState } from 'react';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { profileSubmenu } from '../../profile/ProfileData';
import { AccessControl, AssetCommon } from './_models';
import { Client } from '../../../helpers/models';

type Props = {
  show?: boolean;
  client?: Client;
  asset?: AssetCommon;
  title?: string;
  description?: string;
  onCancel?: () => void;
  onSubmit?: (updates: AccessControl) => void;
};

const AssetShareDialog: FC<Props> = (props) => {
  const [show, setShow] = useState(props.show);

  const assetToAccess = (asset?: AssetCommon) => {
    return {
      client_access: asset?.client_access || false,
      guardians_access: asset?.guardians_access || [],
      public_access: false, //asset?.public_access,
    };
  };

  const [access, setAccess] = useState(assetToAccess(props.asset));

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  // useEffect(() => {
  //   setAccess(assetToAccess(props.asset));
  // }, [props.asset]);

  const handleCancel = () => {
    setAccess(assetToAccess(props.asset));
    props.onCancel?.();
    setShow(false);
  };

  const handleSubmit = () => {
    props.onSubmit?.(access);
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title ? props.title : 'Sharing'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <label className="fw-bold fs-6 mb-1 mt-5">
            {props.description ? props.description : 'Select sharing options:'}
          </label>
          <div className="d-flex flex-wrap align-items-center justify-content-start">
            <Form.Check
              label={`Share with ${props.client?.name}`}
              name="share-client"
              type="checkbox"
              id="share-client"
              className="me-4 mb-0 px-10 py-3"
              checked={access?.client_access || false}
              onChange={(e) => {
                setAccess({ ...access, client_access: e.target.checked });
              }}
            />
            {props.client?.guardians?.map((g) => (
              <Form.Check
                key={g.uuid}
                label={`Share with ${g.name}`}
                name="share-guardians"
                type="checkbox"
                id={`share-guardians-${g.uuid}`}
                className="me-4 mb-0 px-10 py-3"
                checked={access?.guardians_access.some((ga) => ga.uuid === g.uuid)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAccess({ ...access, guardians_access: [...access.guardians_access, g] });
                  } else {
                    setAccess({
                      ...access,
                      guardians_access: access.guardians_access.filter((ga) => ga.uuid !== g.uuid),
                    });
                  }
                }}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline btn-outline-primary me-3" type="button" onClick={handleCancel}>
            Cancel
          </button>

          <button className="btn btn-primary" type="button" onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { AssetShareDialog };
