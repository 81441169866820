import Select, { PropsValue, SingleValue, Options } from 'react-select';
import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { Note, Document } from './_models';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { stateToHTML } from 'draft-js-export-html';
// import { stateFromHTML } from 'draft-js-import-html';
// import draftToHtml from 'draftjs-to-html';
import {
  createDocument,
  updateDocument,
  downloadDocument,
  openDocument,
  useCreateNote,
  useUpdateDocument,
  useUpdateNote,
  getNote,
} from '../../../helpers/api';
import { Interweave } from 'interweave';
import { Button, Spinner } from 'react-bootstrap';
import { Client, ClientProvider } from '../../../helpers/models';
import moment from 'moment';
import { Form } from 'react-router-dom';
import { useAuth } from '../../auth';
import { ConfirmDialog } from '../ConfirmDialog';

import { HtmlInput } from './HtmlInput';
import { monitoringCaptureError } from '../../../helpers/monitoring';
import { AssetShareDialog } from './AssetShareDialog';
import { useAlert } from 'react-alert';

type Props = {
  client: Client;
  clientProvider?: ClientProvider;
  note?: Note;
  onChange?: (newValue: any) => void;
  onCancel?: () => void;
  onSave?: (note?: Note) => void;
  onUpdate?: () => void;
  disabled?: boolean;
  embedded?: boolean;
};

const NoteCard: FC<Props> = (props) => {
  const { currentUser, currentProvider } = useAuth();
  const [note, setNote] = useState(
    props.note || { uuid: undefined, provider: props.clientProvider?.provider || currentProvider }
  );
  const [editMode, setEditMode] = useState(!note || !note.uuid);
  // const blocksFromHTML = convertFromHTML(props.note?.content || '');
  // const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  // const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const attachmentButton = useRef<HTMLInputElement>(null);
  const [processingAttachment, setProcessingAttachment] = useState(false);
  const [processingNote, setProcessingNote] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const alert = useAlert();

  // useEffect(() => {
  //   // const content = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/^|\n$/g, '');
  //   const content = stateToHTML(editorState.getCurrentContent());
  //   setNote({ ...note, content: content });
  // }, [editorState]);

  // useEffect(() => {
  //   // const blocksFromHTML = convertFromHTML(props.note?.content || '');
  //   // const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  //   const contentState = stateFromHTML(props.note?.content || '');
  //   setEditorState(EditorState.createWithContent(contentState));
  // }, [props.note?.content]);

  useEffect(() => {
    if (props.note) setNote(props.note);
  }, [props.note]);

  const handleUpdate = useUpdateNote(
    props.client,
    (updatedNote) => {
      setNote(updatedNote);
      setProcessingNote(false);
      setEditMode(false);
      props.onSave && props.onSave();
    },
    (err) => {
      setProcessingNote(false);
      alert.error('Failed to save note.');
    }
  );
  const handleCreate = useCreateNote(
    props.client,
    (updatedNote) => {
      setNote(updatedNote);
      setProcessingNote(false);
      setEditMode(false);
      props.onSave && props.onSave(updatedNote);
    },
    (err) => {
      setProcessingNote(false);
      alert.error('Failed to add note.');
    }
  );

  const attachFile = (file: File) => {
    setProcessingAttachment(true);
    createDocument({
      client: props.client,
      doc: {
        file: file,
        note: note?.uuid,
      },
    })
      .then((res) => {
        if (res && res.data) {
          props.onUpdate && props.onUpdate();
          getNote(props.client, note.uuid).then((note: Note) => setNote(note));
        }
      })
      .catch((error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'attachFile',
          data: { client: props.client?.uuid, file, note: note?.uuid },
        });
      })
      .finally(() => {
        setProcessingAttachment(false);
      });
  };

  const detachFile = (doc: Document) => {
    setProcessingAttachment(true);
    updateDocument({ client: props.client, doc: { ...doc, note: null } })
      .then((res) => {
        if (res && res.data) {
          props.onUpdate && props.onUpdate();
          setNote(res.data);
        }
      })
      .catch((error) => {
        monitoringCaptureError(error, {
          module: 'api',
          func: 'detachFile',
          data: { client: props.client?.uuid, doc },
        });
      })
      .finally(() => {
        setProcessingAttachment(false);
      });
  };

  const buttons = !currentProvider ? (
    <></>
  ) : editMode ? (
    <>
      <div
        className="btn btn-outline btn-outline-primary btn-sm me-3"
        onClick={() => {
          if (note.uuid) {
            setNote(props.note!);
            setEditMode(false);
          } else {
            props.onCancel && props.onCancel();
          }
        }}
      >
        Cancel
      </div>
      <Button
        className="btn btn-outline btn-outline-primary btn-sm me-3"
        onClick={() => {
          if (note) {
            setProcessingNote(true);
            if (note?.uuid) {
              handleUpdate.mutate(note);
            } else {
              handleCreate.mutate(note);
            }
          }
        }}
        disabled={!note}
      >
        Save {processingNote && <Spinner className="ms-3" animation="border" size="sm" role="status" />}
      </Button>
    </>
  ) : (
    <>
      {note.uuid && (
        <>
          <input
            type="file"
            id="file"
            ref={attachmentButton}
            style={{ display: 'none' }}
            onChange={(event) => {
              if (event) {
                event.stopPropagation();
                event.preventDefault();
                const file = event.target.files && event.target.files[0];
                if (file) {
                  attachFile(file);
                }
              }
            }}
          />
          <div
            className="btn btn-outline btn-outline-primary btn-sm me-3"
            onClick={() => {
              attachmentButton.current && attachmentButton.current.click();
            }}
          >
            Add Attachment{' '}
            {processingAttachment && <Spinner className="ms-3" animation="border" size="sm" role="status" />}
          </div>
          <div
            className="btn btn-outline btn-outline-primary btn-sm me-3"
            onClick={() => {
              setShowShareDialog(true);
            }}
          >
            Share
          </div>
        </>
      )}
      <div
        className="btn btn-outline btn-outline-primary btn-sm me-3"
        onClick={() => {
          setEditMode(true);
        }}
      >
        {note.uuid ? 'Edit' : 'Add'}
      </div>
    </>
  );

  const visibleTo = () => {
    let lst = [note?.provider?.name];
    note.client_access && lst.push(note?.client?.name);
    note.guardians_access &&
      note.guardians_access?.length > 0 &&
      lst.push(...note.guardians_access?.map((g) => g.name));
    return lst.join(', ');
  };

  return (
    <div className={clsx('card', { 'shadow-sm mt-auto mb-5': !props.embedded })}>
      <div className="card-header mobile-header flex-row-reverse">
        <div className="card-toolbar">
          {props.embedded ? (
            <button
              className="btn btn-close px-0"
              onClick={() => {
                props.onCancel && props.onCancel();
              }}
            />
          ) : (
            <>
              <div className="me-5 py-2">{note.created_at && moment(note.created_at).format('LLL')}</div>
              {buttons}
            </>
          )}
        </div>
        <h3 className="card-title me-auto">
          {editMode ? (
            <div className="form-inline">
              <input
                type="text"
                className="form-control form-control-solid min-w-200px d-inline-block"
                style={{ width: note.title ? note.title?.length + 1 + 'ch' : 'auto' }}
                placeholder="Title (optional)"
                value={note.title || ''}
                onChange={(e) => setNote({ ...note, title: e.target.value })}
              />
            </div>
          ) : (
            <div>{note.title}</div>
          )}
        </h3>
      </div>
      <div className="card-body mt-4">
        {/* {editMode ? (
          <Editor
            editorState={editorState}
            onEditorStateChange={(newEditorState) => {
              setEditorState(newEditorState);
            }}
            toolbarClassName={''}
            editorClassName={''}
            wrapperClassName={'editor-box-wrapper'}
            toolbar={{
              options: [
                'inline',
                'fontSize',
                'fontFamily',
                'blockType',
                'list',
                'emoji',
                'image',
                'link',
                'history',
                'textAlign',
              ], // 'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'
              inline: { options: ['bold', 'italic', 'underline'] }, // 'bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'
              blockType: { inDropdown: true },
            }}
          />
        ) : (
          <Interweave content={note?.content} className="providerNotes" placeholder="No notes" />
        )} */}
        <HtmlInput
          content={props.note?.content || ''}
          editMode={editMode}
          className="providerNotes"
          placeholder="No notes"
          formattingOptions={[
            'inline',
            'fontSize',
            'fontFamily',
            'blockType',
            'list',
            'emoji',
            'image',
            'link',
            'history',
          ]}
          onChange={(content) => setNote({ ...note, content })}
        />
        {note?.documents?.map((doc) => (
          <div className="btn btn-outline btn-outline-primary btn-sm me-3 px-2 my-2" key={doc.uuid}>
            <span
              onClick={() => {
                openDocument({ client: props.client, doc });
              }}
            >
              {doc.filename}
            </span>
            <i
              className="fs-3 ms-4 las la-times inherit-color"
              onClick={() => {
                ConfirmDialog({
                  title: 'Remove Attachment',
                  message: `Remove ${doc.filename}?`,
                  confirmButton: 'Remove',
                  confirmClassName: 'btn btn-danger btn-sm',
                  cancelButton: 'Cancel',
                  onProceed: (proceed) => {
                    if (proceed) {
                      detachFile(doc);
                    }
                  },
                });
              }}
            />
          </div>
        ))}
      </div>
      <div className="card-footer py-3 d-flex justify-content-between flex-wrap">
        {note.updated_at && <span className="text-gray-600 me-2">Updated {moment(note.updated_at).format('LLL')}</span>}
        <div className="text-gray-800">
          <i className="fas fa-lock me-1"></i> Visible to {visibleTo()}
        </div>
        {props.embedded && buttons && (
          <div className="d-flex mt-10 justify-content-end">{props.embedded && buttons}</div>
        )}
      </div>
      {showShareDialog && (
        <AssetShareDialog
          show={showShareDialog}
          client={props.client}
          asset={note}
          onCancel={() => setShowShareDialog(false)}
          onSubmit={(access) => {
            const newNote = { ...note, ...access };
            setNote(newNote);
            if (newNote?.uuid) {
              setProcessingNote(true);
              handleUpdate.mutate(newNote);
            }
            setShowShareDialog(false);
          }}
        />
      )}
    </div>
  );
};

export { NoteCard };
