import { parseISO } from 'date-fns';
import { AuthModel } from './_models';
import { nanoid } from 'nanoid';
import { ID, uuid } from '../../../../_metronic/helpers';
import { Provider } from '../../../helpers/models';
import { AxiosError, AxiosRequestConfig } from 'axios';
import ReactGA from 'react-ga4';
import { pathToTitle } from '../../../routing/GAListner';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from './Auth';
import { monitoringCaptureError } from '../../../helpers/monitoring';

const AUTH_LOCAL_STORAGE_KEY = 'wt-app';
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
    monitoringCaptureError(error, { module: 'AuthHelpers', msg: 'AUTH LOCAL STORAGE PARSE ERROR' });
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
    monitoringCaptureError(error, { module: 'AuthHelpers', msg: 'AUTH LOCAL STORAGE SAVE ERROR' });
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
    monitoringCaptureError(error, { module: 'AuthHelpers', msg: 'AUTH LOCAL STORAGE REMOVE ERROR' });
  }
};

let tempDeviceToken: string;
const getDeviceToken = (): string => {
  const wuid = 'duid';
  if (!localStorage) {
    if (tempDeviceToken) return tempDeviceToken;
    else {
      const n = nanoid();
      tempDeviceToken = `n-${n}`;
      return tempDeviceToken;
    }
  }
  const lsValue: string | null = localStorage.getItem(wuid);
  if (lsValue) {
    return lsValue;
  } else {
    const n = nanoid();
    localStorage.setItem(wuid, n);
    return n;
  }
};

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
const dateOnlyFormat = /^\d{4}-\d{2}-\d{2}$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

function isDateOnlyString(value: any): boolean {
  return value && typeof value === 'string' && dateOnlyFormat.test(value);
}

function createLocalDate(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day); // Months are zero-indexed in JavaScript Date objects
  date.setHours(0, 0, 0, 0); // Ensure the time components are set to zero to avoid any local timezone offset issues
  return date;
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (isDateOnlyString(value)) body[key] = createLocalDate(value);
    else if (typeof value === 'object') handleDates(value);
  }
}

let magicToken = '';

let requestInterceptorId: number | undefined = undefined;
function setupAxios(props: {
  axios: any;
  magicToken?: string | undefined | null;
  inviteToken?: string;
  providerOverride?: Provider | undefined | null;
}) {
  if (props.magicToken !== undefined) {
    magicToken = props.magicToken || '';
  }
  props.axios.defaults.headers.Accept = 'application/json';
  if (requestInterceptorId !== undefined) props.axios.interceptors.request.eject(requestInterceptorId);
  requestInterceptorId = props.axios.interceptors.request.use(
    (
      config: AxiosRequestConfig /*{ headers: { Authorization: string; 'Device-Token': string; 'Provider-Override': ID | undefined } }*/
    ) => {
      const auth = getAuth();
      if (auth && auth.token) {
        config.headers.Authorization = `Token ${auth.token}`;
      } else if (magicToken) {
        config.headers.Authorization = `MagicToken ${magicToken}`;
      } else if (props.inviteToken) {
        config.headers.Authorization = `InviteToken ${props.inviteToken}`;
      }

      if ((props.providerOverride && props.providerOverride.uuid) || props.providerOverride === null)
        config.headers['Provider-Override'] = props.providerOverride?.uuid || null;

      config.headers['Device-Token'] = getDeviceToken();

      if (config.method && ['post', 'patch', 'delete'].includes(config.method?.toLowerCase())) {
        const url = new URL(config.url || '');
        const title = pathToTitle(url.pathname.replace('/api/v1/', '')).toLowerCase();
        const method = config.method?.toLowerCase();
        const action =
          method === 'patch' ? 'Update' : method === 'post' ? 'Create' : method === 'delete' ? 'Delete' : method;
        ReactGA.event({
          category: `requests`,
          action: `${title} ${action}`,
        });
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
  props.axios.interceptors.response.use(
    (originalResponse: any) => {
      handleDates(originalResponse.data);
      return originalResponse;
    },
    (error: AxiosError) => {
      if (error.message === 'Network Error') {
        if (!window.location.href.includes('/error/network')) window.location.href = '/error/network';
        // You can display an error message, show a notification, etc.
      }
      if (error.response?.status == 401) {
        // Unauthorized
        const url = new URL(window.location.href);
        const queryString = url.search;
        if (!window.location.href.includes('/auth')) window.location.href = `/auth${queryString}`;
      }
      return Promise.reject(error);
    }
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, setupAxios, handleDates, magicToken };
