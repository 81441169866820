import libphonenumber from 'google-libphonenumber';
import {
  formatPhoneNumber as rpniFormatPhoneNumber,
  formatPhoneNumberIntl as rnpiFormatPhoneNumberIntl,
  parsePhoneNumber as rnpiParsePhoneNumber,
} from 'react-phone-number-input';
import { Provider } from '../modules/apps/providers/core/_models';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const validatePhoneNumber = (phoneNumber: string | undefined) => {
  if (phoneNumber) {
    try {
      const phone = phoneUtil.parse(phoneNumber);
      return phoneUtil.isPossibleNumber(phone);
    } catch (err) {
      return false;
    }
  } else {
    return true;
  }
};

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const isNumber = (x: any) => typeof x === 'number';

const numberOrNull = (x: any) => (isNaN(parseInt(x, 10)) ? null : parseInt(x, 10));

// function to find first valid variable even if it's 0 (workaround for javascript shenanigans)
function getValidNumber(...args: (number | undefined)[]) {
  for (var i = 0; i < args.length; i++) {
    if (isNumber(args[i])) return args[i];
  }
  return undefined;
}

// const formatPhoneNumber = (value: string | undefined) => {
//   let formattedNumber;
//   if (value === undefined) return '';
//   const { length } = value;
//   // Filter non numbers
//   const regex = () => value.replace(/[^+0-9\.]+/g, '');

//   // Set area code with parenthesis around it
//   const countryCode = () => `${regex().slice(0, 2)}`;

//   // Set area code with parenthesis around it
//   const areaCode = () => `${regex().slice(2, 5)}`;

//   // Set formatting for first six digits
//   const firstThree = () => `${regex().slice(5, 8)}`;
//   const lastFour = () => `${regex().slice(8, 12)}`;

//   return `${countryCode()} (${areaCode()}) ${firstThree()}-${lastFour()}`;
// };

const formatPhoneNumber = (phone?: string) => {
  let phoneNumber = phone ? rnpiParsePhoneNumber(phone) : undefined;

  return phoneNumber && phoneNumber.country === 'US'
    ? rpniFormatPhoneNumber(phone!)
    : (phone && rnpiFormatPhoneNumberIntl(phone)) ||
        (phone && rpniFormatPhoneNumber(phone)) ||
        (phone && rpniFormatPhoneNumber('+1' + phone)) ||
        phone ||
        '';
};

function calculateDistanceKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  // Convert latitude and longitude to radians
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lon1Rad = (lon1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lon2Rad = (lon2 * Math.PI) / 180;

  // Calculate the differences between the latitudes and longitudes
  const latDiff = lat2Rad - lat1Rad;
  const lonDiff = lon2Rad - lon1Rad;

  // Calculate the distance using the Haversine formula
  const a = Math.sin(latDiff / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lonDiff / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;
  return distance;
}

function calculateDistanceMiles(lat1: number, lon1: number, lat2: number, lon2: number): number {
  return calculateDistanceKm(lat1, lon1, lat2, lon2) * 1.6;
}

const isRichTextEmpty = (text?: string) => {
  const strippedText = text
    ? text
        .replace(/<[^>]*>/g, '')
        .replace(/[\n\s]/g, '')
        .trim()
    : '';
  return strippedText.length === 0;
};

function stringsFancyJoin(inputList: string[]) {
  if (inputList.length === 0) {
    return '';
  }

  if (inputList.length === 1) {
    return inputList[0];
  }

  const lastItem = inputList.pop();
  return inputList.join(', ') + ' and ' + lastItem;
}

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || '';

const titlelize = (s: string) => {
  return s
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

const titlelizeDashedString = (s: string) => {
  return s
    .toLowerCase()
    .split('-')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

const titlelizeUnderscoredString = (s: string) => {
  return s
    .toLowerCase()
    .split('_')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

const renderWithLineBreaks = (textWithLineBreaks: string, className?: string) => {
  const lines = textWithLineBreaks.split('\n');
  return lines.map((line: string, index: number) => (
    <p key={index} className={className}>
      {line}
    </p>
  ));
};

const getDaysAgo = (days: number): Date => {
  const today = new Date();
  today.setDate(today.getDate() - days);
  return today;
};

const isDateLessThanDaysOld = (dateToCheck: Date, days: number): boolean => {
  const twoWeeksAgo = getDaysAgo(days);
  return dateToCheck > twoWeeksAgo;
};

const fixToUTC = (d: Date) => {
  // rrule return string with 'Z' ending even though it's in local time :-(
  return new Date(d.toISOString().slice(0, -1));
};

function objectsHaveSameContent<T extends object>(obj1: T, obj2: T): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  function compareUndefinedNull(var1: any, var2: any) {
    if ((var1 === undefined && var2 === null) || (var1 === null && var2 === undefined)) {
      return true;
    } else {
      return false;
    }
  }

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (
      obj1[key as keyof T] !== obj2[key as keyof T] &&
      !compareUndefinedNull(obj1[key as keyof T], obj2[key as keyof T])
    ) {
      return false;
    }
  }

  return true;
}

function lightenColor(color: string, factor: number): string {
  // Ensure the input is a valid hex color
  if (!/^#[0-9A-Fa-f]{6}$/.test(color)) {
    throw new Error('Invalid hex color format');
  }

  // Parse the hex color
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  // Lighten each color component
  r = Math.min(255, Math.floor(r + (255 - r) * factor));
  g = Math.min(255, Math.floor(g + (255 - g) * factor));
  b = Math.min(255, Math.floor(b + (255 - b) * factor));

  // Convert back to hex and return the new color
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

function isBrightColor(backgroundColor: string): boolean {
  // Convert hex color to RGB
  const hexToRgb = (hex: string): [number, number, number] => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
  };

  // Calculate the luminance of the color
  const luminance = (r: number, g: number, b: number): number => {
    const a = [r, g, b].map((value) => {
      value /= 255;
      return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  // Convert background color to RGB
  const [r, g, b] = hexToRgb(backgroundColor);

  // Get luminance
  const bgLuminance = luminance(r, g, b);

  // Return black or white text color based on luminance
  return bgLuminance > 0.4;
}

function getContrastColor(backgroundColor: string, lighten?: boolean): string {
  return isBrightColor(backgroundColor) ? (lighten ? '#777' : 'black') : lighten ? '#ddd' : 'white';
}

const providerAndClinicName = (provider?: Provider) =>
  provider?.clinic?.name ? `${provider?.clinic?.name} (${provider?.name})` : provider?.name;

export {
  validatePhoneNumber,
  delay,
  isNumber,
  getValidNumber,
  formatPhoneNumber,
  numberOrNull,
  calculateDistanceKm,
  calculateDistanceMiles,
  isRichTextEmpty,
  stringsFancyJoin,
  capitalize,
  titlelize,
  titlelizeDashedString,
  titlelizeUnderscoredString,
  isDateLessThanDaysOld,
  fixToUTC,
  objectsHaveSameContent,
  renderWithLineBreaks,
  getDaysAgo,
  lightenColor,
  getContrastColor,
  providerAndClinicName,
  isBrightColor,
};
