import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { HomeWrapper } from '../pages/home/HomeWrapper';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { useAuth } from '../modules/auth';
import { ClientNotes } from '../modules/apps/clients/client-details/ClientNotes';
import { ClientDocuments } from '../modules/apps/clients/client-details/ClientDocuments';

const PrivateRoutes = () => {
  const { currentUser, currentProvider, currentClient, currentGuardian } = useAuth();
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));
  const ServicesPage = lazy(() => import('../modules/apps/services/ServicesPage'));
  const AvailabilityPage = lazy(() => import('../modules/apps/availability/AvailabilityPage'));
  //const IntegrationsPage = lazy(() => import('../modules/apps/integrations/IntegrationsPage'));
  //const PreferencesPage = lazy(() => import('../modules/apps/preferences/PreferencesPage'));
  const ClientsPage = lazy(() => import('../modules/apps/clients/ClientsPage'));
  const ClientDetailsPage = lazy(() => import('../modules/apps/clients/ClientDetailsPage'));
  const ProvidersPage = lazy(() => import('../modules/apps/providers/ProvidersPage'));
  const ProviderDetailsPage = lazy(() => import('../modules/apps/providers/ProviderDetailsPage'));
  const ProviderDocuments = lazy(() => import('../modules/apps/providers/ProviderDocuments'));
  const SchedulePage = lazy(() => import('../modules/apps/schedule/SchedulePage'));
  const BookingPage = lazy(() => import('../modules/apps/booking/BookingPage'));
  const FinancialsPage = lazy(() => import('../modules/apps/financials/FinancialsPage'));
  const ClinicPage = lazy(() => import('../modules/apps/providers/ClinicPage'));
  const ReferralsPage = lazy(() => import('../modules/apps/referrals/ReferralsPage'));
  const ReferralProvidersPage = lazy(() => import('../modules/apps/referrals/ReferralProvidersPage'));
  const ReferralDetailsPage = lazy(() => import('../modules/apps/referrals/ReferralDetails'));
  const HomePage = lazy(() => import('../modules/apps/home/HomePage'));
  const SetupPage = lazy(() => import('../modules/apps/home/SetupPage'));
  const ComingSoon = lazy(() => import('../pages/ComingSoon'));
  const WhatsNew = lazy(() => import('../pages/WhatsNew'));
  const ProviderSupport = lazy(() => import('../pages/ProviderSupport'));
  const ProvidersFAQ = lazy(() => import('../pages/ProvidersFAQ'));
  const Onboarding = lazy(() => import('../modules/apps/clients/OnboardingPage'));
  const LoadingPage = lazy(() => import('../modules/widgets/LoadingPage'));
  const Intake = lazy(() => import('../modules/apps/clients/IntakePage'));
  const Form = lazy(() => import('../modules/apps/clients/FormPage'));
  const ClientBillingPage = lazy(() => import('../modules/apps/clients/ClientBillingPage'));
  const RescheduleRedirect = lazy(() => import('../modules/apps/clients/RescheduleRedirect'));
  const Reschedule = lazy(() => import('../modules/apps/clients/ReschedulePage'));

  return (
    <Routes>
      <Route path="loading" element={<LoadingPage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path="auth/*" element={<Navigate to="/home" />} />
        {/* Pages */}
        <Route path="home" element={<HomePage />} />
        <Route path="setup" element={<SetupPage />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="services/*"
          element={
            <SuspensedView>
              <ServicesPage />
            </SuspensedView>
          }
        />
        <Route
          path="availability/*"
          element={
            <SuspensedView>
              <AvailabilityPage />
            </SuspensedView>
          }
        />
        {/*<Route
          path="preferences/*"
          element={
            <SuspensedView>
              <PreferencesPage />
            </SuspensedView>
          }
        />*/}
        <Route
          path="clients/*"
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path="clients/:clientId/*"
          element={
            <SuspensedView>
              <ClientDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path="providers/*"
          element={
            <SuspensedView>
              <ProvidersPage />
            </SuspensedView>
          }
        />
        <Route
          path="client-provider/:clientProviderId/*"
          element={
            <SuspensedView>
              <ClientDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path="providers/new/"
          element={
            <SuspensedView>
              <ProviderDetailsPage newProvider={true} />
            </SuspensedView>
          }
        />
        <Route
          path="providers/:providerId/*"
          element={
            <SuspensedView>
              <ProviderDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path="schedule/*"
          element={
            <SuspensedView>
              <SchedulePage />
            </SuspensedView>
          }
        />
        <Route
          path="booking/*"
          element={
            <SuspensedView>
              <BookingPage />
            </SuspensedView>
          }
        />
        <Route
          path="clinic/*"
          element={
            <SuspensedView>
              <ClinicPage />
            </SuspensedView>
          }
        />
        <Route
          path="clinics/:clinicId/*"
          element={
            <SuspensedView>
              <ClinicPage />
            </SuspensedView>
          }
        />
        <Route
          path="referrals/providers/*"
          element={
            <SuspensedView>
              <ReferralProvidersPage />
            </SuspensedView>
          }
        />
        <Route
          path="referrals/*"
          element={
            <SuspensedView>
              <ReferralsPage />
            </SuspensedView>
          }
        />
        {currentProvider && (
          <Route
            path="documents/*"
            element={
              <SuspensedView>
                <ProviderDocuments />
              </SuspensedView>
            }
          />
        )}
        {currentClient && (
          <>
            <Route
              path="documents/*"
              element={
                <SuspensedView>
                  <ClientDocuments client={currentClient} />
                </SuspensedView>
              }
            />
            <Route
              path="notes/*"
              element={
                <SuspensedView>
                  <ClientNotes client={currentClient} />
                </SuspensedView>
              }
            />
          </>
        )}
        {currentGuardian && (
          <>
            <Route
              path="documents/*"
              element={
                <SuspensedView>
                  {currentGuardian.clients && currentGuardian.clients.length > 0 ? (
                    currentGuardian.clients?.map((c) => (
                      <>
                        <h1 className="my-5 mb-10">{c.full_name}</h1>
                        <ClientDocuments client={c} />
                      </>
                    ))
                  ) : (
                    <h1>No Clients</h1>
                  )}
                </SuspensedView>
              }
            />
            <Route
              path="notes/*"
              element={
                <SuspensedView>
                  {currentGuardian.clients && currentGuardian.clients.length > 0 ? (
                    currentGuardian.clients?.map((c) => (
                      <>
                        <h1 className="my-5">{c.full_name}</h1>
                        <ClientNotes client={c} />
                      </>
                    ))
                  ) : (
                    <h1>No Clients</h1>
                  )}
                </SuspensedView>
              }
            />
          </>
        )}

        {/*<Route
          path="integrations/*"
          element={
            <SuspensedView>
              <IntegrationsPage />
            </SuspensedView>
          }
        />*/}
        <Route
          path="ComingSoon/*"
          element={
            <SuspensedView>
              <ComingSoon />
            </SuspensedView>
          }
        />
        <Route
          path="WhatsNew/*"
          element={
            <SuspensedView>
              <WhatsNew />
            </SuspensedView>
          }
        />
        <Route
          path="ProviderSupport/*"
          element={
            <SuspensedView>
              <ProviderSupport />
            </SuspensedView>
          }
        />
        <Route
          path="ProvidersFAQ/*"
          element={
            <SuspensedView>
              <ProvidersFAQ />
            </SuspensedView>
          }
        />
        <Route
          path="billing/*"
          element={
            <SuspensedView>
              <ClientBillingPage />
            </SuspensedView>
          }
        />
        <Route
          path="financials/*"
          element={
            <SuspensedView>
              <FinancialsPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path="reschedule/:token/"
          element={
            <SuspensedView>
              <RescheduleRedirect />
            </SuspensedView>
          }
        /> */}
        <Route path="reschedule/:token/" element={<Reschedule />} />

        {/* Page Not Found */}
      </Route>
      <Route path="i/:inviteId" element={<Onboarding />} />
      <Route path="onboarding/:inviteId" element={<Onboarding />} />
      <Route path="onboarding/" element={<Onboarding />} />
      <Route path="intake/:inviteId" element={<Intake />} />
      <Route path="intake/" element={<Intake />} />
      <Route path="form/:formId" element={<Form />} />
      <Route path="/:formId/:inviteId" element={<Intake />} />
      <Route path="/:formId/:inviteId/" element={<Intake />} />
      <Route path="/:formId/" element={<Intake />} />
      <Route path="*" element={<Navigate to="/error/404" />} />
    </Routes>
  );
};

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
