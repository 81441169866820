import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import { Languages } from './Languages';
import { toAbsoluteUrl } from '../../../helpers';
import clsx from 'clsx';
import { Client } from '../../../../app/helpers/models';

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const name =
    currentUser?.provider?.name ||
    currentUser?.guardian?.full_name ||
    currentUser?.client?.full_name ||
    currentUser?.first_name + ' ' + currentUser?.last_name;
  const initials =
    currentUser?.provider?.initials ||
    currentUser?.guardian?.initials ||
    currentUser?.client?.initials ||
    currentUser?.initials;
  const email =
    currentUser?.provider?.email || currentUser?.guardian?.email || currentUser?.client?.email || currentUser?.email;
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            {/*<img alt="Logo" src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />*/}

            {/* begin:: Avatar */}
            {currentUser?.avatar ? (
              <img src={toAbsoluteUrl(`/media/${currentUser?.avatar}`)} alt={name} className="w-100" />
            ) : (
              <div className={clsx('symbol-label fs-3', `bg-light-primary`, `text-primary`)}>{initials}</div>
            )}
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {name}
              {/*<span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span>*/}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        {currentUser?.provider ? (
          <Link to={toAbsoluteUrl(`/providers/${currentUser?.provider.uuid}/profile`)} className="menu-link px-5">
            {/* was /crafted/pages/profile */}
            Provider Profile
          </Link>
        ) : currentUser?.guardian && currentUser?.guardian.clients && currentUser?.guardian.clients.length > 0 ? (
          currentUser?.guardian.clients.map((guardianClient: Client) => (
            <Link
              to={toAbsoluteUrl(`/clients/${guardianClient.uuid}/profile`)}
              className="menu-link px-5"
              key={guardianClient.uuid}
            >
              {/* was /crafted/pages/profile */}
              {guardianClient.full_name} Profile
            </Link>
          ))
        ) : (
          currentUser?.client && (
            <Link to={toAbsoluteUrl(`/clients/${currentUser?.client.uuid}/profile`)} className="menu-link px-5">
              {/* was /crafted/pages/profile */}
              Client Profile
            </Link>
          )
        )}
      </div>

      {/*<div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          <span className="menu-text">My Projects</span>
          <span className="menu-badge">
            <span className="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
          </span>
        </a>
      </div>

      <div
        className="menu-item px-5"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="menu-link px-5">
          <span className="menu-title">My Subscription</span>
          <span className="menu-arrow"></span>
        </a>

        <div className="menu-sub menu-sub-dropdown w-175px py-4">
          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Referrals
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Billing
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Payments
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex flex-stack px-5">
              Statements
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="View your statements"
              ></i>
            </a>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-3">
            <div className="menu-content px-3">
              <label className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  value="1"
                  defaultChecked={true}
                  name="notifications"
                />
                <span className="form-check-label text-muted fs-7">Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          My Statements
        </a>
      </div>

      <div className="separator my-2"></div>

      <Languages />

      <div className="menu-item px-5 my-1">
        <Link to="/crafted/account/settings" className="menu-link px-5">
          Account Settings
        </Link>
      </div>
*/}
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
