import { FC } from 'react';
import { get_git_sha, is_production, get_deployment, get_long_version } from '../../../app/helpers/environment';
import { useLayout } from '../core';

const Footer: FC = () => {
  const { classes } = useLayout();
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <a href="https://wiztherapy.com/terms" target="_blank" className="text-gray-800 text-hover-primary me-2">
            Terms
          </a>
          <a href="https://wiztherapy.com/privacy" target="_blank" className="text-gray-800 text-hover-primary me-4">
            Privacy
          </a>
          <span className="text-muted fw-bold ms-2 me-2">&copy; {new Date().getFullYear()}</span>
          <a href="/" className="text-gray-800 text-hover-primary me-2">
            WizTherapy
          </a>
        </div>
        <div className="text-gray-200 order-2 order-md-1">
          {is_production() ? (
            <span>{get_git_sha()}</span>
          ) : (
            <span className="bg-danger text-white px-3">
              {get_deployment()} {get_git_sha()}
            </span>
          )}
        </div>

        {/* end::Copyright */}

        {/* begin::Nav */}
        {/*
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a href="#" className="menu-link ps-0 pe-2">
              About
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0 pe-2">
              Contact
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0">
              Purchase
            </a>
          </li>
        </ul>
        */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
