import { FC, useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Client, IWizForm, Provider } from '../../../helpers/models';
import { BsChevronDown, BsChevronLeft, BsChevronUp } from 'react-icons/bs';
import moment from 'moment';
import { isDateLessThanDaysOld } from '../../../helpers/funcs';
import { WizFormViewer } from './WizFormViewer';

type Props = {
  form: IWizForm;
};

const FormCard: FC<Props> = (props) => {
  const form = props.form;
  const [collapsed, setCollapsed] = useState(form.complete_date && !isDateLessThanDaysOld(form.complete_date, 30));

  return (
    <div className="card shadow-sm mt-auto mb-5" key={form.uuid}>
      <div
        className="card-header  mobile-header cursor-pointer"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <h3 className="card-title">{form.form_template_display_name}</h3>
        <div className="card-toolbar">
          <span className="me-4 pt-1">{moment(form.complete_date).format('LLL')}</span>
          <span>{collapsed ? <BsChevronDown /> : <BsChevronUp />}</span>
        </div>
      </div>
      <Collapse isOpened={!collapsed}>
        <div className="card-body">
          <WizFormViewer form={form} />
        </div>
      </Collapse>
    </div>
  );
};

export { FormCard };
