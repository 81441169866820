import * as Sentry from '@sentry/browser';
import { UserModel } from '../modules/auth';

const monitoringSetUser = (user: UserModel) => {
  if (user) {
    const initials = user.initials || user.provider?.initials || user.client?.initials || user.guardian?.initials;
    Sentry.setUser({ id: user.uuid, name: initials, email: user.email });
  }
};

const monitoringCaptureError = (err: any, extra?: any) => {
  console.error('Error: ', err, extra);
  Sentry.captureException(err, {
    extra: extra || {},
  });
};

const monitoringCaptureMessage = (message: string) => {
  Sentry.captureMessage(message);
};

export { monitoringSetUser, monitoringCaptureError, monitoringCaptureMessage };
