export const get_deployment = () => {
  if (location.hostname === 'localhost') return 'dev';
  if (location.hostname.includes('sta')) return 'staging';
  else return 'production';
};

export const is_production = () => {
  return get_deployment() === 'production';
};

export const is_dev = () => {
  return get_deployment() === 'dev';
};

export const should_monitor = () => {
  return get_deployment() != 'dev';
};

export const get_build_date = () => {
  return process.env.REACT_APP_BUILD_DATE;
};

export const get_git_branch = () => {
  return process.env.REACT_APP_GIT_BRANCH;
};

export const get_git_sha = () => {
  return process.env.REACT_APP_GIT_SHA;
};

export const get_long_version = () => {
  return `${get_build_date()}-${get_git_branch()}-${get_git_sha()}`;
};

export const get_google_client_id = () => {
  return process.env.REACT_APP_GOOGLE_CLIENT_ID
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID
    : is_production()
    ? '1022574145099-38f8h8vh0qoq32269f4rtaj649soq7ds.apps.googleusercontent.com'
    : '310650749707-a3jdi6dl0ukp23vn53e5pu9cpg0h6c6i.apps.googleusercontent.com';
};

export const is_fb_ads = (wizCampaign: string | undefined | null) => {
  return wizCampaign && wizCampaign.length > 0 && wizCampaign.startsWith('fb');
};

export const is_fb_pixel = (pixel: string | undefined | null) => {
  return pixel && pixel.length > 0;
};

export const is_google_ads = (wizCampaign: string | undefined | null) => {
  return wizCampaign && wizCampaign.length > 0 && wizCampaign.startsWith('ga');
};
