import { FC, useEffect, useRef, useState } from 'react';
import { Clinic, Provider } from '../../../helpers/models';
import { useAuth } from '../../auth';
import Select, { PropsValue, SingleValue, Options } from 'react-select';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingIndicator } from '../../apps/clients/client-details/LoadingIndicator';
import { Spinner } from 'react-bootstrap';

type Props = {
  clinic: Provider;
};

const ClinicTitleBar: FC<Props> = (props) => {
  const { providerOverride, setProviderOverride, currentProvider, currentUser, currentProviderUpdating } = useAuth();
  function providerSorter(a: Provider, b: Provider): number {
    const nameA = a.name?.toUpperCase() || '';
    const nameB = b.name?.toUpperCase() || '';

    if (a.is_org) return -3;
    if (a.uuid === currentUser?.provider?.uuid) return -2;
    if (b.uuid === currentUser?.provider?.uuid) return 2;
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  }
  const providerLabel = (clinic?: Provider, provider?: Provider) => {
    if (provider?.is_org) return `${clinic?.name}: Clinic View`;
    else if (provider?.uuid == currentUser?.provider?.uuid)
      return (
        <span className="fw-bolder me-2">
          {clinic?.name}: {provider?.name} <span className="fw-lighter">(you)</span>
        </span>
      );
    else return `${clinic?.name}: ${provider?.name}`;
  };

  const providers = (props.clinic.providers || []).concat(props.clinic);
  const clinicOptions =
    providers.sort(providerSorter).map((p) => ({
      value: p.uuid as string,
      label: providerLabel(props.clinic, p),
    })) || [];

  let location = useLocation();
  let navigate = useNavigate();
  const rerouteIfNeeded = () => {
    const url = location.pathname;
    const clientsRegex = /^\/clients\/[a-f0-9-]+\//;
    const clientProviderRegex = /^\/client-provider\/[a-f0-9-]+\//;
    if (clientsRegex.test(url) || clientProviderRegex.test(url)) {
      navigate('clients/');
    }
  };

  return (
    <>
      {props.clinic.is_admin ? (
        <>
          <Select
            classNamePrefix="serviceSelect"
            className={clsx(
              'form-control form-control-solid pt-0 pb-0 me-3 serviceSelect min-w-200px mw-400px w-auto clinicTitleBar',
              {
                'bg-white  border-primary border-3':
                  providerOverride && providerOverride.uuid !== currentUser?.provider?.uuid,
                'bg-white': !(providerOverride && providerOverride.uuid !== currentUser?.provider?.uuid),
              }
            )}
            aria-label="Clinic"
            placeholder={props.clinic.name + ': select provider' + '\u00A0\u00A0'}
            value={
              providerOverride
                ? { value: providerOverride.uuid, label: providerLabel(props.clinic, providerOverride) }
                : { value: currentProvider?.uuid, label: providerLabel(props.clinic, currentProvider) }
            }
            onChange={(val: any) => {
              const provider = val ? providers.find((p: Provider) => val.value === p.uuid) : undefined;
              rerouteIfNeeded();
              setProviderOverride(provider);
            }}
            options={clinicOptions}
            isSearchable={true}
            // isDisabled={}
            styles={{ menu: (base) => ({ ...base, zIndex: 1000 }) }}
            isClearable={currentUser?.provider?.uuid != currentProvider?.uuid}
          ></Select>
          {currentProviderUpdating && (
            <div className="center-screen-loading-indicator">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </>
      ) : (
        <h1>{props.clinic.name}</h1>
      )}
    </>
  );
};

export { ClinicTitleBar };
