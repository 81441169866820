import clsx from 'clsx';
import React, { FC } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials';
import { useLayout } from '../../core';
import { useAuth } from '../../../../app/modules/auth';
import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { is_production } from '../../../../app/helpers/environment';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1';

const tooltip = (
  <Tooltip id="tooltip" className="fs-6">
    Coming Soon
  </Tooltip>
);

const Topbar: FC = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* Search */}
      {/*<div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div>*/}
      {/* Activities */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/* begin::Drawer toggle */}
      {/*   <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>*/}
      {/* end::Drawer toggle */}
      {/*</div>*/}

      {/* NOTIFICATIONS */}
      {!is_production() && (
        <div className="alert alert-danger my-3 d-flex align-items-center" role="alert">
          <span className="fs-2">
            <i className="fs-4 me-1 bi bi-exclamation-circle inherit-color"></i> TEST APP
          </span>
        </div>
      )}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <KTSVG path="/media/icons/duotune/general/gen007.svg" className={toolbarButtonIconSizeClass} />
        </div>
        <HeaderNotificationsMenu />
        {/* end::Menu wrapper */}
      </div>

      {/* CHAT */}
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          <Link
            to={'/ComingSoon/chat_topbar'}
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            id="kt_drawer_chat_toggle"
          >
            <KTSVG path="/media/icons/duotune/communication/com012.svg" className={toolbarButtonIconSizeClass} />
          </Link>

          {/* Green bubble indicator */}
          {/*<span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>*/}
          {/*</div>*/}
          {/* end::Menu wrapper */}
        </div>
      </OverlayTrigger>

      {/* Quick links */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/* begin::Menu wrapper */}
      {/*  <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />*/}
      {/* end::Menu wrapper */}
      {/*</div>*/}

      {/* begin::User */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)} id="kt_header_user_menu_toggle">
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol symbol-circle ps-2', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {/*<img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic' />*/}

          {/* begin:: Avatar */}
          {currentUser?.avatar ? (
            <img
              src={toAbsoluteUrl(`/media/${currentUser?.avatar}`)}
              alt={currentUser?.first_name + ' ' + currentUser?.last_name}
              className="w-100"
            />
          ) : (
            <div
              className={clsx(
                'symbol-label fs-4',
                'border border-2 border-primary',
                `bg-light-primary`,
                `text-primary`
              )}
            >
              {currentUser?.initials}
            </div>
          )}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG path="/media/icons/duotune/text/txt001.svg" className="svg-icon-1" />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
