import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { KTSVG, useDebounce } from '../../../../../_metronic/helpers';
import { useNotes } from '../../../../helpers/api';
import { NoteCard } from '../../../widgets/components/NoteCard';
import { Client, ClientProvider } from '../core/_models';
import { getClientById } from '../core/_requests';

type Props = {
  client?: Client;
  clientProvider?: ClientProvider;
  isLoading?: boolean;
};

const ClientNotes: FC<Props> = (props) => {
  const [addNew, setAddNew] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const { refetch, status: notesStatus, data: notes } = useNotes(props.client, debouncedSearchTerm, !!props.client);

  useEffect(() => {
    if (props.client) refetch();
  }, [debouncedSearchTerm]);

  return (
    <>
      <div className="card shadow-sm mt-auto mb-5">
        <div className="card-header mobile-header">
          <h3 className="card-title">
            <div className={clsx({ 'd-flex align-items-center position-relative my-1': true })}>
              <KTSVG path="/media/icons/duotune/general/gen021.svg" className="svg-icon-1 position-absolute ms-3" />
              <input
                type="text"
                data-kt-client-table-filter="search"
                className={clsx('form-control form-control-solid ps-12 btn-sm' /*, { 'w-250px ': !compact }*/)}
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={!props.client}
              />
              <button
                type="button"
                className="btn bg-transparent"
                style={{ marginLeft: '-50px', zIndex: '1' }}
                onClick={() => {
                  setSearchTerm('');
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </h3>
          <div className="card-toolbar">
            <Button
              className="btn btn-outline btn-outline-primary btn-sm me-3"
              onClick={() => {
                setAddNew(true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      {props.client && (
        <>
          {addNew && (
            <NoteCard
              client={props.client}
              clientProvider={props.clientProvider}
              onCancel={() => {
                setAddNew(false);
              }}
              onSave={() => {
                setAddNew(false);
                refetch();
              }}
            />
          )}
          {notes &&
            notes.map((note) => (
              <NoteCard
                client={props.client!}
                clientProvider={props.clientProvider}
                note={note}
                key={note.uuid}
                onUpdate={() => {
                  refetch();
                }}
              />
            ))}
        </>
      )}
    </>
  );
};

export { ClientNotes };
