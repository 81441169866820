import { FC, useState, useEffect, createContext, useContext, useRef, Dispatch, SetStateAction } from 'react';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { AuthModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { getUserByToken } from './_requests';

import { Client, Guardian, Provider } from '../../../helpers/models';
import { setupAxios } from './AuthHelpers';
import axios, { AxiosError } from 'axios';
import { QueryCache, useQueryClient } from 'react-query';
import { getProviderById } from '../../apps/providers/core/_requests';
import { useAlert } from 'react-alert';
import { SwapperComponent } from '../../../../_metronic/assets/ts/components';
import { useNavigate } from 'react-router-dom';
import { monitoringCaptureError, monitoringSetUser } from '../../../helpers/monitoring';

export type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  providerOverride: Provider | undefined | null;
  setProviderOverride: Dispatch<SetStateAction<Provider | undefined>>;
  currentProvider: Provider | undefined;
  currentClient: Client | undefined;
  currentGuardian: Guardian | undefined;
  currentProviderUpdating: boolean;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  providerOverride: undefined,
  setProviderOverride: () => {},
  currentProvider: undefined,
  currentClient: undefined,
  currentGuardian: undefined,
  currentProviderUpdating: false,
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [providerOverride, setProviderOverride] = useState<Provider | undefined>();
  const [currentProvider, setCurrentProvider] = useState<Provider | undefined>();
  const [currentClient, setCurrentClient] = useState<Client | undefined>();
  const [currentGuardian, setCurrentGuardian] = useState<Guardian | undefined>();
  const [currentProviderUpdating, setCurrentProviderUpdating] = useState(false);
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };
  const queryCache = new QueryCache();
  const alert = useAlert();

  useEffect(() => {
    setupAxios({ axios, providerOverride });
    if (providerOverride?.uuid) {
      setCurrentProviderUpdating(true);
      getProviderById(providerOverride.uuid)
        .then((data) => setCurrentProvider(data))
        .catch((error) => {
          alert.error('Failed to switch provider');
          monitoringCaptureError(error, { msg: `failed to switch to provider ${providerOverride.uuid}` });
        })
        .finally(() => {
          setCurrentProviderUpdating(false);
        });
    } else {
      setCurrentProvider(currentUser?.provider || undefined);
    }
    if (!currentUser?.provider) {
      if (currentUser?.guardian) setCurrentGuardian(currentUser?.guardian);
      else if (currentUser?.client) setCurrentClient(currentUser?.client);
    }

    queryCache.clear();
    SwapperComponent.updateAll();
  }, [providerOverride, currentUser]);

  const logout = () => {
    const queryCache = new QueryCache();
    saveAuth(undefined);
    setCurrentUser(undefined);
    setCurrentProvider(undefined);
    setProviderOverride(undefined);
    setCurrentClient(undefined);
    setCurrentGuardian(undefined);
    setCurrentProviderUpdating(false);
    queryCache.clear();
    window.history.pushState({}, '', '/');
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        providerOverride,
        setProviderOverride,
        currentProvider,
        currentClient,
        currentGuardian,
        logout,
        currentProviderUpdating,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC = ({ children }) => {
  const { auth, logout, setCurrentUser, currentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken();
          if (data) {
            setCurrentUser(data);
            monitoringSetUser(data);
          }
        }
      } catch (error) {
        const networkError = axios.isAxiosError(error) && (error as AxiosError).message == 'Network Error';
        console.error(`auth ${networkError && 'network'} error`, error);

        monitoringCaptureError(error);
        if (!networkError && !didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if ((auth && auth.token) || authHelper.magicToken) {
      requestUser();
    } else {
      if (currentUser) logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

const refreshUser = async (authContext: AuthContextProps) => {
  const { setCurrentUser } = authContext;
  const { data } = await getUserByToken();
  if (data) {
    setCurrentUser(data);
    monitoringSetUser(data);
  }
};

export { AuthProvider, AuthInit, useAuth, refreshUser };
