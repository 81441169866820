import { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
//import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit } from './modules/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { get_google_client_id } from './helpers/environment';

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* <I18nProvider> */}
      <LayoutProvider>
        <AuthInit>
          <GoogleOAuthProvider clientId={get_google_client_id()}>
            <Outlet />
          </GoogleOAuthProvider>
          <MasterInit />
        </AuthInit>
      </LayoutProvider>
      {/* </I18nProvider> */}
    </Suspense>
  );
};

export { App };
