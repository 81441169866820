/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, lazy } from 'react';
import { Routes, Route, BrowserRouter, Navigate, useSearchParams } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import { GAListener } from './GAListner';
const Onboarding = lazy(() => import('../modules/apps/clients/OnboardingPage'));
const Intake = lazy(() => import('../modules/apps/clients/IntakePage'));
const Reschedule = lazy(() => import('../modules/apps/clients/ReschedulePage'));

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const url = new URL(window.location.href);
  const queryString = url.search;
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <GAListener>
        <Routes>
          <Route element={<App />}>
            <Route path="error/*" element={<ErrorsPage />} />
            <Route path="logout" element={<Logout />} />
            {currentUser ? (
              <>
                <Route path="/*" element={<PrivateRoutes />} />
                <Route index element={<Navigate to="/home" />} />
              </>
            ) : (
              <>
                <Route path="reschedule/:token/" element={<Reschedule />} />
                <Route path="i/:inviteId" element={<Onboarding />} />
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="account-setup" element={<Navigate to={`/auth/account-setup/${queryString}`} />} />
                <Route path="/:formId/:inviteId" element={<Intake />} />
                <Route path="/:formId/:inviteId/" element={<Intake />} />
                <Route path="/:formId/" element={<Intake />} />
                <Route path="*" element={<Navigate to={`/auth${queryString}`} />} />
              </>
            )}
          </Route>
        </Routes>
      </GAListener>
    </BrowserRouter>
  );
};

export { AppRoutes };
