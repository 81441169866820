import { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { PasswordReset } from './components/PasswordReset';
import { PasswordSetup } from './components/PasswordSetup';

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/wiz/wiz_bg.svg')})`,
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl('/media/logos/wiz-logo-white.svg')} className="h-45px colorize-logo" />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}

      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="https://wiztherapy.com/terms" target="_blank" className="text-muted text-hover-primary px-2">
            Terms
          </a>

          <a href="https://wiztherapy.com/privacy" target="_blank" className="text-muted text-hover-primary px-2">
            Privacy
          </a>

          <span className="text-muted fw-bold ms-2">&copy; {new Date().getFullYear()}</span>
          <a href="https://www.wiztherapy.com" className="text-muted text-hover-primary px-2">
            WizTherapy
          </a>
        </div>
      </div>

      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword action="forgot" />} />
      <Route path="password-reset-confirmation" element={<PasswordReset />} />
      <Route path="password-setup" element={<PasswordSetup />} />
      <Route
        path="account-setup"
        element={
          <ForgotPassword
            title={
              <>
                <div className="text-center mb-10">
                  {/* begin::Title */}
                  <h1 className="text-dark mb-3">Account Setup</h1>
                  {/* end::Title */}
                </div>
                <div>
                  {/* begin::Link */}
                  <div className="text-gray-800 fw-bold fs-4 mt-5 mb-10">
                    Please validate your email. We'll send you an email to complete your setup.
                  </div>
                  {/* end::Link */}
                </div>
              </>
            }
            noCancel={true}
            action="setup"
          />
        }
      />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage, AuthLayout };
