import { useEffect, useState, FC } from 'react';
import Form from '@rjsf/bootstrap-4';
import { RegistryWidgetsType, RJSFSchema, WidgetProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { getFormTemplate } from '../../../helpers/api';
import { IWizForm } from '../../../helpers/models';

import {
  WizCheckboxesViewerWidget,
  InputViewerWidget,
  PasswordViewerWidget,
  EmptyWidget,
} from './WizFormViewerWidgets';
import moment from 'moment';
import { monitoringCaptureError } from '../../../helpers/monitoring';
import { Interweave } from 'interweave';

type Props = {
  form: IWizForm;
};

const WizFormViewer: FC<Props> = (props) => {
  const [formData, setFormData] = useState(props.form.data);
  const [bookedSlots, setBookedSlots] = useState(props.form.booked_slots);
  const [schema, setSchema] = useState<RJSFSchema>();
  const [uiSchema, setUiSchema] = useState<any>([]);

  useEffect(() => {
    setFormData(props.form.data);
  }, [props.form.data]);

  useEffect(() => {
    setBookedSlots(props.form.booked_slots);
  }, [props.form.booked_slots]);

  const BookingWidget = (props: WidgetProps) => {
    return (
      <>
        {bookedSlots &&
          bookedSlots?.map((slot) => (
            <div key={slot.uuid}>
              {slot.service?.name}
              {' at '}
              {moment(slot.start).format('MMMM D, YYYY [at] h:mm')}
              {' - '}
              {moment(slot.end).format('h:mm A')}
            </div>
          ))}
      </>
    );
  };

  const HtmlWidget: React.FC<WidgetProps> = ({ id, value, onChange }) => {
    return (
      <div>
        <Interweave content={value} />
      </div>
    );
  };

  interface NewSchema {
    properties: { [key: string]: any };
    definitions: { [key: string]: any };
    dependencies: { [key: string]: any };
    allOf: any[];
  }

  const WizWidgets: RegistryWidgetsType = {
    BookingWidget: BookingWidget,
    WizCheckboxesWidget: WizCheckboxesViewerWidget,
    RadioWidget: WizCheckboxesViewerWidget,
    TextWidget: InputViewerWidget,
    NumberField: InputViewerWidget,
    BooleanField: InputViewerWidget,
    DateWidget: InputViewerWidget,
    EmailWidget: InputViewerWidget,
    FileWidget: InputViewerWidget,
    SelectWidget: InputViewerWidget,
    TextareaWidget: InputViewerWidget,
    TimeWidget: InputViewerWidget,
    URLWidget: InputViewerWidget,
    AltDateTimeWidget: InputViewerWidget,
    AltDateWidget: InputViewerWidget,
    CheckboxesWidget: WizCheckboxesViewerWidget,
    CheckboxWidget: WizCheckboxesViewerWidget,
    DateTimeWidget: InputViewerWidget,
    RangeWidget: InputViewerWidget,
    UpDownWidget: InputViewerWidget,
    PasswordWidget: PasswordViewerWidget,
    HtmlWidget: HtmlWidget,
    // DescriptionField: EmptyWidget,
  };

  function removeDescriptionKeys(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => removeDescriptionKeys(item));
    }

    const newObj: any = {};
    for (const key in obj) {
      if (!key.includes('description')) {
        newObj[key] = removeDescriptionKeys(obj[key]);
      }
    }

    return newObj;
  }

  function generateSchema(ft: any) {
    if (!ft.schema || !ft.ui_schema) {
      return;
    } else if (Array.isArray(ft.schema)) {
      try {
        let newSchema: NewSchema = { properties: {}, definitions: {}, dependencies: {}, allOf: [] };
        ft.schema &&
          ft.schema.forEach((s: any) => {
            newSchema.properties = { ...newSchema.properties, ...(s.properties || {}) };
            newSchema.definitions = { ...newSchema.definitions, ...(s.definitions || {}) };
            newSchema.dependencies = { ...newSchema.dependencies, ...(s.dependencies || {}) };
            newSchema.allOf = [...newSchema.allOf, ...(s.allOf || [])];
          });
        newSchema = removeDescriptionKeys(newSchema);

        let newUiSchema: any = { 'ui:order': [] };
        ft.ui_schema &&
          ft.ui_schema.forEach((s: any) => {
            const keys = Object.keys(s).filter((k) => k != 'ui:order');

            if (s.hasOwnProperty('ui:order')) newUiSchema['ui:order'] = [...newUiSchema['ui:order'], ...s['ui:order']];
            keys &&
              keys.forEach((k) => {
                newUiSchema[k] = s[k];
              });
          });
        newUiSchema = removeDescriptionKeys(newUiSchema);

        setSchema(newSchema);
        setUiSchema(newUiSchema);
      } catch (err) {
        console.error(err);
      }
    } else {
      setSchema(removeDescriptionKeys(ft.schema));
      setUiSchema(removeDescriptionKeys(ft.ui_schema));
    }
  }

  useEffect(() => {
    getFormTemplate(props.form.form_template)
      .then((data) => {
        if (data) {
          try {
            generateSchema(data);
            // }
          } catch (error) {
            monitoringCaptureError(error, {
              module: 'WizForm',
              // message: `failed to load form template ${props.id}`,
            });
          }
        }
      })
      .catch((error) => {
        monitoringCaptureError(error, {
          module: 'WizForm',
          // message: `failed to load form template ${props.id}`,
        });
      });
  }, [props.form.form_template]);

  return (
    <div className="WtForm container py-0 px-2">
      <div className="row">
        <div className="col-12">
          {uiSchema && schema && (
            <Form
              schema={schema}
              validator={validator}
              uiSchema={uiSchema}
              formData={formData}
              widgets={WizWidgets}
              showErrorList={false}
              noHtml5Validate={true}
            >
              <div>{/* this hides the submit button */}</div>
            </Form>
          )}
          {schema === null && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="row form-spinner">
                <div className="spinner-border" role="status"></div>
              </div>
              <div className="row">
                <strong>Loading...</strong>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export { WizFormViewer };
