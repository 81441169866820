import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { getUserByToken, passwordReset, passwordTokenValid } from '../core/_requests';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import { useAuth } from '../core/Auth';
import YupPassword from 'yup-password';

import { Spinner } from 'react-bootstrap';
import { ForgotPassword } from './ForgotPassword';
import { monitoringCaptureError } from '../../../helpers/monitoring';
YupPassword(Yup);

const passwordResetSchema = Yup.object().shape({
  password: Yup.string().password().required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Password and Confirm Password do not match'),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});

export function PasswordSetup() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [tokenValid, setTokenValid] = useState<boolean | undefined>();
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const initialValues = {
    token: token,
    password: '',
    changepassword: '',
    acceptTerms: false,
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const formik = useFormik({
    initialValues,
    validationSchema: passwordResetSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (values.token) {
          await passwordReset(values.token, values.password);
        }
        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);
        setStatus('Password setup successful');
        setHasErrors(false);
      } catch (error) {
        console.error(error);
        monitoringCaptureError(error, { module: 'PasswordSetup' });
        saveAuth(undefined);
        setStatus('Password setup has failed');
        setSubmitting(false);
        setLoading(false);
        setHasErrors(false);
      }
    },
  });

  useEffect(() => {
    if (token)
      passwordTokenValid(token)
        .then((res) => {
          if (res.status === 200) {
            setTokenValid(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setTokenValid(false);
        });
  }, [token]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return tokenValid === undefined ? (
    <>
      <div className="mb-10 text-center">
        <h1 className="text-dark mb-3">Password Setup</h1>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </>
  ) : tokenValid === false ? (
    <>
      <ForgotPassword
        title={
          <>
            <div className="text-center mb-10">
              {/* begin::Title */}
              <h1 className="text-dark mb-3">Password Setup</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className="text-gray-800 fw-bold fs-4 mt-5">The link has expired.</div>
              <div className="text-gray-800 fw-bold fs-4 mt-5">
                Please enter your email below to setup your password.
              </div>
              {/* end::Link */}
            </div>
          </>
        }
        noCancel={true}
        action="setup"
      />
    </>
  ) : (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">Account Setup</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div
          className={clsx('mb-lg-15 alert text-center', {
            'alert-success': hasErrors === false,
            'alert-danger': hasErrors === true,
          })}
        >
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {hasErrors !== false && (
        <>
          {/* begin::Form group Password */}
          <div className="mb-10 fv-row" data-kt-password-meter="true">
            <div className="mb-1">
              <label className="form-label fw-bolder text-dark fs-6">Password</label>
              <div className="position-relative mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* begin::Meter */}
              <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
              </div>
              {/* end::Meter */}
            </div>
            <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className="fv-row mb-5">
            <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
            <input
              type="password"
              placeholder="Password confirmation"
              autoComplete="off"
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Form group */}
          <div className="fv-row mt-10 mb-10">
            <div className="form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                id="kt_login_toc_agree"
                {...formik.getFieldProps('acceptTerms')}
              />
              <label className="form-check-label fw-bold text-gray-700 fs-6" htmlFor="kt_login_toc_agree">
                I Agree the{' '}
                <a href="https://wiztherapy.com/terms" className="ms-1 link-primary" target="_blank">
                  terms and conditions
                </a>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}
          <div className="text-center">
            <button
              type="submit"
              id="kt_sign_up_submit"
              className="btn btn-lg btn-primary w-100 mb-5"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className="indicator-label">Submit</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_signup_form_cancel_button"
                className="btn btn-lg btn-light-primary w-100 mb-5"
              >
                Cancel
              </button>
            </Link>
          </div>
        </>
      )}
      {/* end::Form group */}

      {hasErrors === false && (
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            Go to Login Page
          </button>
        </Link>
      )}
    </form>
  );
}
