import Select, { PropsValue, SingleValue, Options } from 'react-select';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Client, ClientProvider, Provider, ProviderService } from '../../../helpers/models';
import { useClientProvider, useClientProviders, useClients, useProviderServices } from '../../../helpers/api';
import { useAuth } from '../../auth';
import { providerAndClinicName } from '../../../helpers/funcs';

type Props = {
  provider: Provider | undefined;
  onChange?: (newProvider: Provider | undefined) => void;
  className?: string;
  classNamePrefix?: string;
  disabled?: boolean;
  placeHolder?: string;
  emailMatchList?: string[];
};

const ClientProviderSelect: FC<Props> = (props) => {
  const { currentUser } = useAuth();

  const { data: clientProviders, status, isLoading } = useClientProviders();

  const options = clientProviders?.map((cp: ClientProvider) => ({
    value: cp.provider?.uuid as string,
    label: providerAndClinicName(cp.provider) || '',
  }));

  useEffect(() => {
    if (!props.provider && clientProviders && clientProviders.length == 1)
      if (props.onChange) props.onChange(clientProviders[0].provider);
  }, [clientProviders]);

  return (
    <Select
      classNamePrefix={props.classNamePrefix || 'serviceSelect'}
      className={props.className || 'form-control form-control-solid pt-0 pb-0 mb-3 pe-3 me-3 serviceSelect'}
      aria-label="Provider Selection"
      placeholder={props.placeHolder || 'Select a provider...'}
      value={
        (props.provider && {
          value: props.provider.uuid as string,
          label: providerAndClinicName(props.provider) || '',
        }) ||
        null
      }
      onChange={(val: any) => {
        const cp = clientProviders?.find((cp: ClientProvider) => val.value === cp.provider?.uuid);
        if (props.onChange) props.onChange(cp?.provider);
      }}
      isLoading={isLoading}
      options={options}
      isSearchable={true}
      isDisabled={props.disabled}
      styles={{ menu: (base) => ({ ...base, zIndex: 1000 }) }}
    ></Select>
  );
};

export { ClientProviderSelect };
