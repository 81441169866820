import axios, { AxiosResponse } from 'axios';
import { ID, Response } from '../../../../../_metronic/helpers';
import {
  Provider,
  ProvidersQueryResponse,
  ProviderAccreditation,
  ProviderAccreditationQueryResponse,
  Clinic,
  ProviderConnectionsQueryResponse,
  IProviderConnection,
} from './_models';
import { CLINIC_PROVIDER_URL, CLINIC_URL, PROVIDER_CONNECTIONS_URL, PROVIDER_URL } from '../../../../helpers/api';
import { monitoringCaptureError } from '../../../../helpers/monitoring';

const getProviders = (query: string, onError?: (error?: any) => void): Promise<ProvidersQueryResponse | undefined> => {
  return axios
    .get(`${PROVIDER_URL}?${query}`)
    .then((d: AxiosResponse<ProvidersQueryResponse>) => d.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'getProviders',
        data: { query },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const getClinicProviders = (
  query: string,
  onError?: (error?: any) => void
): Promise<ProvidersQueryResponse | undefined> => {
  return axios
    .get(`${CLINIC_PROVIDER_URL}?${query}`)
    .then((d: AxiosResponse<ProvidersQueryResponse>) => d.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'getClinicProviders',
        data: { query },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const getProviderById = (
  providerUuid: ID,
  query?: string,
  onError?: (error?: any) => void
): Promise<Provider | undefined> => {
  return axios
    .get(`${PROVIDER_URL}${providerUuid}/?${query}`)
    .then((response: AxiosResponse<Provider>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'getProviderById',
        data: { providerUuid },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const createProvider = (provider: Provider, onError?: (error?: any) => void): Promise<Provider | undefined> => {
  return axios
    .post(PROVIDER_URL, provider)
    .then((response: AxiosResponse<Provider>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'createProvider',
        data: { provider },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const updateProvider = (provider: Provider, onError?: (error?: any) => void): Promise<Provider | undefined> => {
  return axios
    .patch(`${PROVIDER_URL}${provider.uuid}/`, provider)
    .then((response: AxiosResponse<Provider>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'updateProvider',
        data: { provider: provider },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const deleteProvider = (providerUuid: ID, onError?: (error?: any) => void): Promise<void> => {
  return axios
    .delete(`${PROVIDER_URL}${providerUuid}/`)
    .then(() => {})
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'deleteProvider',
        data: { providerUuid },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const deleteSelectedProviders = (providerUuids: Array<ID>, onError?: (error?: any) => void): Promise<void> => {
  const requests = providerUuids.map((uuid) =>
    axios.delete(`${PROVIDER_URL}${uuid}/`).catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'deleteSelectedProviders',
        data: { uuid, providerUuids },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    })
  );
  return axios.all(requests).then(() => {});
};

const inviteProvider = (provider: Provider, toClinic?: boolean, onError?: (error?: any) => void): Promise<void> => {
  return axios
    .post(`${PROVIDER_URL}${provider.uuid}/invite/`, { to_clinic: toClinic })
    .then(() => {})
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'deleteProviderConnection',
        data: { provider: provider.uuid, toClinic },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const getAccreditations = (
  providerUuid: ID,
  query: string,
  onError?: (error?: any) => void
): Promise<ProviderAccreditationQueryResponse | undefined> => {
  return axios
    .get(`${PROVIDER_URL}/${providerUuid}/accreditations?${query}`)
    .then((d: AxiosResponse<ProviderAccreditationQueryResponse>) => d.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'getAccreditations',
        data: { providerUuid, query },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const getProviderAccreditationById = (
  providerUuid: ID,
  providerAccreditationUuid: ID,
  onError?: (error?: any) => void
): Promise<ProviderAccreditation | undefined> => {
  return axios
    .get(`${PROVIDER_URL}/${providerUuid}/accreditations/${providerAccreditationUuid}/`)
    .then((response: AxiosResponse<ProviderAccreditation>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'deleteSelectedProviders',
        data: { providerUuid, providerAccreditationUuid },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const createProviderAccreditation = (
  providerUuid: ID,
  providerAccreditation: ProviderAccreditation,
  onError?: (error?: any) => void
): Promise<ProviderAccreditation | undefined> => {
  return axios
    .post(`${PROVIDER_URL}${providerUuid}/accreditations/`, providerAccreditation)
    .then((response: AxiosResponse<ProviderAccreditation>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'createProviderAccreditation',
        data: { providerUuid, providerAccreditation },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const updateProviderAccreditation = (
  providerUuid: ID,
  providerAccreditation: ProviderAccreditation,
  onError?: (error?: any) => void
): Promise<ProviderAccreditation | undefined> => {
  return axios
    .patch(`${PROVIDER_URL}${providerUuid}/accreditations/${providerAccreditation.uuid}/`, providerAccreditation)
    .then((response: AxiosResponse<ProviderAccreditation>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'updateProviderAccreditation',
        data: { providerUuid, providerAccreditation },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const createClinic = (clinic: Provider, onError?: (error?: any) => void): Promise<Provider | undefined> => {
  return axios
    .post(CLINIC_URL, clinic)
    .then((response: AxiosResponse<Provider>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'createClinic',
        data: { clinic },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const updateClinic = (clinic: Provider, onError?: (error?: any) => void): Promise<Provider | undefined> => {
  return axios
    .patch(`${CLINIC_URL}${clinic.uuid}/`, clinic)
    .then((response: AxiosResponse<Provider>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'updateClinic',
        data: { clinic },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const getProviderConnections = (
  query: string,
  onError?: (error?: any) => void
): Promise<ProviderConnectionsQueryResponse | undefined> => {
  return axios
    .get(`${PROVIDER_CONNECTIONS_URL}?${query}`)
    .then((d: AxiosResponse<ProviderConnectionsQueryResponse>) => d.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'getProviderConnections',
        data: { query },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const createProviderConnection = (
  providerConnection: IProviderConnection,
  onError?: (error?: any) => void
): Promise<IProviderConnection | undefined> => {
  return axios
    .post(PROVIDER_CONNECTIONS_URL, providerConnection)
    .then((response: AxiosResponse<IProviderConnection>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'createProviderConnection',
        data: { providerConnection },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const updateProviderConnection = (
  providerConnection: IProviderConnection,
  onError?: (error?: any) => void
): Promise<IProviderConnection | undefined> => {
  return axios
    .patch(`${PROVIDER_CONNECTIONS_URL}${providerConnection.uuid}/`, providerConnection)
    .then((response: AxiosResponse<IProviderConnection>) => response.data)
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'updateProviderConnection',
        data: { providerConnection },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

const deleteProviderConnection = (
  providerConnection: IProviderConnection,
  onError?: (error?: any) => void
): Promise<void> => {
  return axios
    .delete(`${PROVIDER_CONNECTIONS_URL}${providerConnection.uuid}/`)
    .then(() => {})
    .catch((error) => {
      monitoringCaptureError(error, {
        module: 'providers._requests',
        func: 'deleteProviderConnection',
        data: { providerConnection },
      });
      if (onError) onError(error);
      else throw error;

      return undefined;
    });
};

export {
  getProviders,
  getClinicProviders,
  deleteProvider,
  deleteSelectedProviders,
  getProviderById,
  createProvider,
  updateProvider,
  inviteProvider,
  getAccreditations,
  getProviderAccreditationById,
  createProviderAccreditation,
  updateProviderAccreditation,
  createClinic,
  updateClinic,
  getProviderConnections,
  createProviderConnection,
  updateProviderConnection,
  deleteProviderConnection,
};
