import Select, { PropsValue, SingleValue, Options } from 'react-select';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { Document } from './_models';
import { Editor } from 'react-draft-wysiwyg';
import { Collapse } from 'react-collapse';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {
  openDocument,
  downloadDocument,
  getDocumentThumbnailUrl,
  useCreateDocument,
  useUpdateDocument,
  deleteDocument,
} from '../../../helpers/api';
import { Interweave } from 'interweave';
import { Button } from 'react-bootstrap';
import { Client, Provider } from '../../../helpers/models';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { useAuth } from '../../auth';
import { copyFile } from 'fs';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { BsChevronDown, BsChevronLeft, BsChevronUp } from 'react-icons/bs';
import { ConfirmDialog } from '../ConfirmDialog';
import { useAlert } from 'react-alert';
import { AssetShareDialog } from './AssetShareDialog';
import { ClientProviderSelect } from './ClientProviderSelect';

type Props = {
  client?: Client;
  provider?: Provider;
  doc?: Document;
  onChange?: (newValue: any) => void;
  onCancel?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
};

const DocumentCard: FC<Props> = (props) => {
  const { currentUser, currentProvider, currentClient, currentGuardian } = useAuth();
  const [doc, setDoc] = useState(
    props.doc || { uuid: undefined, provider: props.provider || currentProvider, client: props.client || currentClient }
  );
  const [editMode, setEditMode] = useState(false);
  const [uploadMode, setUploadMode] = useState(!doc?.uuid);
  const [thumbnail, setThumbnail] = useState<string>();
  const [showShareDialog, setShowShareDialog] = useState(false);

  const alert = useAlert();

  const handleUpload = useCreateDocument({
    client: props.client,
    provider: props.provider,
    doc,
    onSuccess: (updatedDoc) => {
      setDoc(updatedDoc);
      setUploadMode(false);
      props.onSave && props.onSave();
    },
  });

  const handleUpdate = useUpdateDocument({
    client: props.client,
    provider: props.provider,
    onSuccess: (updatedDoc) => {
      setDoc(updatedDoc);
      setEditMode(false);
      props.onSave && props.onSave();
    },
  });

  const doc2icon = (doc?: Document) => {
    if (doc?.filetype?.includes('image')) return 'blank-image.svg';
    if (doc?.filename?.includes('.doc')) return 'doc.svg';
    if (doc?.filetype?.includes('pdf')) return 'pdf.svg';
    if (doc?.filename?.includes('.doc')) return 'doc.svg';
    return 'upload.svg';
  };

  const icon = `/media/svg/files/${doc2icon(doc)}`;

  useEffect(() => {
    if (doc.has_thumbnail) {
      getDocumentThumbnailUrl({ client: props.client, provider: props.provider, doc }).then((res) => {
        res && setThumbnail(res);
      });
    }
  }, [doc]);

  const fileExt = doc?.filename && doc?.filename.includes('.') ? '.' + doc?.filename.split('.').pop() : '';
  const fileNoExt = doc?.filename ? doc?.filename.slice(0, -fileExt.length) : '';

  const [collapsed, setCollapsed] = useState(true);

  const visibleTo = () => {
    let lst = [doc?.provider?.name];
    doc.client_access && lst.push(doc?.client?.name);
    doc.guardians_access && doc.guardians_access?.length > 0 && lst.push(...doc.guardians_access?.map((g) => g.name));
    return lst.join(', ');
  };

  return (
    // <div className="col-12 col-sm-12 col-xl">
    //   <Card4 icon="/media/svg/files/pdf.svg" title="Project Reqs.." description="3 days ago" />
    // </div>
    <div className={clsx('card shadow-sm mt-auto mb-5', { 'h-100': !uploadMode })}>
      <div
        className="card-header mobile-header flex-row-reverse my-2"
        style={{ display: 'flex', alignItems: 'center', minHeight: '50px' }}
      >
        <div className="card-toolbar">
          {uploadMode || editMode ? (
            <>
              <div
                className="btn btn-outline btn-outline-primary btn-sm me-3"
                onClick={() => {
                  if (doc.uuid) {
                    setDoc(props.doc!);
                    setEditMode(false);
                  } else {
                    props.onCancel && props.onCancel();
                  }
                }}
              >
                Cancel
              </div>
              <Button
                className="btn btn-outline btn-outline-primary btn-sm me-3"
                onClick={() => {
                  if (doc.uuid) handleUpdate.mutate(doc);
                  else handleUpload.mutate();
                }}
                disabled={uploadMode && (!doc.file || !doc.provider)}
              >
                {uploadMode ? 'Upload' : 'Save'}
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={clsx('card-title me-auto', { 'my-0': !uploadMode && !editMode })}>
          {editMode || uploadMode ? (
            <div className="form-inline">
              <input
                type="text"
                className="form-control form-control-solid min-w-200px"
                //style={{ width: doc.description ? doc.description.length + 1 + 'ch' : 'auto' }}
                placeholder="Description (optional)"
                value={doc.description || ''}
                onChange={(e) => setDoc({ ...doc, description: e.target.value })}
              />
            </div>
          ) : (
            <a
              href="#"
              className="text-gray-800 text-hover-primary d-flex flex-column"
              onClick={(e) => {
                const newWindow = window.open();
                openDocument({ client: props.client, provider: props.provider, doc, newWindow });
              }}
            >
              <span className="text-truncate" style={{ maxWidth: '220px' }}>
                {doc.description || doc.filename}
              </span>
            </a>
          )}
        </div>
      </div>
      <div className={clsx('card-body', { 'd-flex justify-content-center text-center flex-column p-2': !uploadMode })}>
        {uploadMode ? (
          <>
            {(currentClient || currentGuardian) && (
              <>
                <Form.Group controlId="providerSelect" className="my-3 mb-6">
                  <Form.Label>Provider</Form.Label>
                  <ClientProviderSelect
                    classNamePrefix="serviceSelect"
                    className="form-control form-control-solid pt-0 pb-0 mb-3 pe-3 me-3 serviceSelect"
                    provider={doc.provider}
                    onChange={(newProvider) => {
                      setDoc({ ...doc, provider: newProvider });
                    }}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group controlId="formFile" className="my-3">
              <Form.Label>File</Form.Label>
              <Form.Control
                type="file"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target.files && event.target.files[0];
                  if (file) setDoc({ ...doc, file: file });
                  else setDoc({ ...doc, file: undefined });
                }}
              />
            </Form.Group>
          </>
        ) : (
          <>
            <div
              className={clsx('mb-2 cursor-pointer', {
                'symbol symbol-75px': !thumbnail,
                'thumbnail-height': thumbnail,
              })}
              onClick={(e) => {
                openDocument({ client: props.client, provider: props.provider, doc });
              }}
            >
              {thumbnail ? (
                <img src={thumbnail} alt="" style={{ maxHeight: '75px' }} />
              ) : (
                <img src={toAbsoluteUrl(icon)} alt="" />
              )}
            </div>
            {editMode ? (
              <div className="form-inline">
                <input
                  type="text"
                  className="form-control form-control-solid min-w-200px d-inline-block"
                  style={{ width: fileNoExt ? fileNoExt.length + 1 + 'ch' : 'auto' }}
                  placeholder="Filename"
                  value={fileNoExt || ''}
                  onChange={(e) => setDoc({ ...doc, filename: e.target.value + fileExt })}
                />
                <span>{fileExt}</span>
              </div>
            ) : (
              <a
                href="#"
                className="text-gray-800 text-hover-primary d-flex flex-column"
                onClick={(e) => {
                  const newWindow = window.open();
                  openDocument({ client: props.client, provider: props.provider, doc, newWindow });
                }}
              >
                {/* <div className="fs-5 fw-bolder mb-2">{doc?.filename ? doc.filename : ''}</div> */}
              </a>
            )}
          </>
        )}
      </div>

      {!uploadMode && (
        <div className="card-footer py-3 d-flex justify-content-between flex-wrap">
          <button
            className="btn btn-sm btn-outline btn-outline-primary h-30px pt-1"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            Details
          </button>
          <div>
            {currentProvider && (
              <>
                <div
                  className="btn btn-outline btn-outline-primary btn-icon w-30px h-30px me-3"
                  onClick={() => {
                    setShowShareDialog(true);
                  }}
                >
                  <i className="fas fa-share-square inherit-color" />
                </div>

                <div
                  className="btn btn-outline btn-outline-primary btn-icon w-30px h-30px me-3"
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <i className="fas fa-edit inherit-color" />
                </div>
              </>
            )}
            <div
              className="btn btn-outline btn-outline-primary btn-icon w-30px h-30px me-3"
              onClick={() => {
                downloadDocument({ client: props.client, provider: props.provider, doc });
              }}
            >
              <i className="fas fa-download inherit-color" />
            </div>
            {currentProvider && (
              <div
                className="btn btn-outline btn-outline-primary btn-icon w-30px h-30px"
                onClick={() => {
                  ConfirmDialog({
                    title: 'Remove Document',
                    message: `Remove ${doc.description || doc.filename}?`,
                    confirmButton: 'Remove',
                    confirmClassName: 'btn btn-danger btn-sm',
                    cancelButton: 'Cancel',
                    onProceed: (proceed) => {
                      if (proceed)
                        deleteDocument({
                          client: props.client,
                          provider: props.provider,
                          doc,
                          onSuccess: () => {
                            alert.info('Document removed');
                            props.onDelete && props.onDelete();
                          },
                          onError: () => {
                            alert.error('Unable to delete document');
                          },
                        });
                    },
                  });
                }}
              >
                <i className="fas fa-trash inherit-color" />
              </div>
            )}
          </div>

          <Collapse isOpened={!collapsed}>
            <div className="mt-2">
              {doc.provider ? <div className="text-gray-600 me-2">Provider: {doc.provider.name}</div> : <span></span>}
              {doc.filemodified ? (
                <div className="text-gray-600 me-2">Created: {moment(doc.filemodified).format('MMM D, YY')}</div>
              ) : (
                <span></span>
              )}
              {doc.created_at ? (
                <div className="text-gray-600 me-2">Updated: {moment(doc.updated_at).format('MMM D, YY')}</div>
              ) : (
                <span></span>
              )}
              <div className="text-gray-800">
                <i className="fas fa-lock me-1"></i> Visible to {visibleTo()}
              </div>
            </div>
          </Collapse>
        </div>
      )}
      {showShareDialog && (
        <AssetShareDialog
          show={showShareDialog}
          client={props.client}
          asset={doc}
          onCancel={() => setShowShareDialog(false)}
          onSubmit={(access) => {
            const newDoc = { ...doc, ...access };
            setDoc(newDoc);
            if (newDoc?.uuid) {
              handleUpdate.mutate(newDoc);
            }
            setShowShareDialog(false);
          }}
        />
      )}
    </div>
  );
};

export { DocumentCard };
