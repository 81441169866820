import React, { FC, ReactNode, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { requestPassword } from '../core/_requests';

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

export const ForgotPassword: FC<{ title?: ReactNode; noCancel?: boolean; action?: string }> = (props) => {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const formik = useFormik({
    initialValues: { ...initialValues, email: searchParams.get('email') || searchParams.get('username') || '' },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      try {
        await requestPassword(values.email, props.action);
        setHasErrors(false);
        setLoading(false);
      } catch (error) {
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus('Password reset request has failed');
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        {props.title ? (
          props.title
        ) : (
          <div className="text-center mb-10">
            {/* begin::Title */}
            <h1 className="text-dark mb-3">Forgot Password?</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className="text-gray-400 fw-bold fs-4">Enter your email to reset your password.</div>
            {/* end::Link */}
          </div>
        )}
        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Password reset has failed.
              <br />
              Please contact support@wiztherapy.com.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info text-center">
              Sent password reset link.
              <br />
              Please check your email.
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        {hasErrors !== false && (
          <>
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
              <input
                type="email"
                placeholder="Enter email"
                autoComplete="off"
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                disabled={formik.isSubmitting || loading}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
              <button
                type="submit"
                id="kt_password_reset_submit"
                className="btn btn-lg btn-primary fw-bolder me-4"
                disabled={formik.isSubmitting || !formik.isValid || loading}
              >
                {!loading ? (
                  <span className="indicator-label">Submit</span>
                ) : (
                  <span className="indicator-label">Requesting...</span>
                )}
              </button>
              {!props.noCancel && (
                <Link to="/auth/login">
                  <button
                    type="button"
                    id="kt_login_password_reset_form_cancel_button"
                    className="btn btn-lg btn-light-primary fw-bolder"
                    disabled={formik.isSubmitting || !formik.isValid || loading}
                  >
                    Cancel
                  </button>
                </Link>
              )}
            </div>
          </>
        )}
        {/* end::Form group */}
      </form>
    </>
  );
};
