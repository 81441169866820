import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  isComingSoon?: boolean;
  target?: string;
  suffixIcon?: string;
  disabled?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isComingSoon = false,
  target,
  suffixIcon,
  disabled,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className={clsx('menu-item', { 'opacity-25': !!disabled })}>
      <Link
        className={clsx('menu-link without-sub', { active: isActive })}
        to={to}
        target={target}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className="menu-title d-flex">
          {title}
          {suffixIcon && <i className={clsx('las ms-1 fs-3 inherit-color', suffixIcon)}></i>}
          {isComingSoon && (
            <span className="position-relative top-25 end-0 ms-1 fs-10 text-dark translate-middle-y badge rounded-pill bg-lime">
              Coming Soon
            </span>
          )}
        </span>
      </Link>
      {children}
    </div>
  );
};

export { AsideMenuItem };
