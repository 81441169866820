import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ping } from '../../../helpers/api';

const ErrorNetwork: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    console.error('offline');
    const intervalId = setInterval(async () => {
      const alive = await ping();
      if (alive) {
        console.error('back online');
        clearInterval(intervalId);
        navigate(-1);
      }
    }, 5000);
  }, []);
  return (
    <>
      <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Unable to reach network.</h1>

      <div className="fw-bold fs-3 text-gray-400 mb-15">Please try again in a few minutes.</div>
    </>
  );
};

export { ErrorNetwork };
